import { useState } from 'react';

const useAdminLocation = () => {
  const [location, setLocation] = useState(parseInt(localStorage.getItem('currentLocation')) || null);

  const setAdminLocation = (value) => {
    if (value) {
      setLocation(value);
      localStorage.setItem('currentLocation', value);
    }
  };

  return [location, setAdminLocation];
};

export default useAdminLocation;
