import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';


let selectedToArray = (selected) => {
  let arrayVersion = [];
  for (let selection of selected) {
    for (let i=0; i<selection.amount; i++) {
      arrayVersion.push(selection.value);
    }
  }
  return arrayVersion;
};

const initialSelected = (arr, options) => {
  let initialSelected = {};
  if (!arr) return [{}];
  let filteredOptions = options.filter(option => arr.includes(option.value));
  for (let option of filteredOptions) {
    initialSelected[option.value] = { text: option.text, amount: 0, value: option.value };
  }
  for (let selection of arr) {
    if (initialSelected[selection]) {
      initialSelected[selection].amount ++;
    }
  }
  return Object.keys(initialSelected).length > 0 ? Object.values(initialSelected) : [{}];
};

const timeOptions = [
  { text: '0.5 hour', value: 1 },
  { text: '1 hour', value: 2 },
  { text: '1.5 hours', value: 3 },
  { text: '2 hours', value: 4 },
  { text: '2.5 hours', value: 5 },
  { text: '3 hours', value: 6 },
  { text: '3.5 hours', value: 7 },
  { text: '4 hours', value: 8 }
];

export default function ServiceSelector({ options, onChange, value }) {
  const [selected, setSelected] = useState(initialSelected(value, options));

  const select = (index, value) => {
    const newSelected = [...selected];
    newSelected[index] = { ...newSelected[index], ...value };
    setSelected(newSelected);
    onChange(selectedToArray(newSelected));
  };

  const addRow = (e) => {
    e.stopPropagation();
    setSelected(current => {
      return [ ...current, {} ];
    });
  };

  const removeRow = (index) => {
    const newSelected = [...selected];
    newSelected.splice(index, 1);
    setSelected(newSelected);
    onChange(selectedToArray(newSelected));
  };

  return (
    <div className="border border-ui-gray-100 bg-ui-gray-20 flex flex-col gap-1 p-5 rounded-md relative" role="combobox" tabIndex="0" >
      <div className="flex gap-3">
        <label className="w-auto flex-auto">Select a Task</label>
        <label className="w-auto flex-auto flex-grow-0 pr-4">Estimated Time Length</label>
      </div>
      {selected.map(({ text, amount, value }, index) => (
        <div className="flex gap-3 items-center" key={index}>
          <Dropdown
            className="w-auto flex-auto bg-ui-gray-40"
            placeholder='Select A Task'
            fluid
            selection
            value={value}
            onChange={(e, { value }) => select(index, { value: value, text })}
            options={options}
          />
          <Dropdown
            className="w-auto flex-auto flex-grow-0 bg-ui-gray-40"
            placeholder='Time Length'
            fluid
            selection
            value={amount}
            onChange={(e, { value }) => select(index, { amount: value })}
            options={timeOptions}
          />
          <FontAwesomeIcon onClick={() => removeRow(index)} className={`${!value && !amount && index == 0 && 'invisible'} text-ui-gray-80 cursor-pointer`} icon={faTrash} />
        </div>
      ))}
      <button onClick={addRow} className="bg-white p-3 rounded-md mt-4 text-ui-gray-40 mr-6 border hover:border-ui-gray-100 hover:text-ui-gray-100">
        <FontAwesomeIcon icon={faPlus} /> Add a Task
      </button>
    </div>
  );
}
