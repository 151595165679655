import axios from 'axios';
import { saveHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const sendMessage = (text, conversation_id) => {
  return axios.post('/messages', {
    text,
    conversation_id
  })
    .then(response => saveHeaders(response.headers))
    .catch(res => handleRequestError(res, 'sendMessage'));
};

export const getUnread = () => {
  return axios.get('/conversations/unread_count')
    .then(res => {
      saveHeaders(res.headers);
      return res.data.unread;
    });
  // .catch(res => handleRequestError(res, 'getUnread'));
};

export const createConversation = (conversation) => {
  return axios.post('/conversations/', conversation)
    .catch(res => handleRequestError(res, 'createConversation'));
};

export const leaveConversation = (conversationId) => {
  return axios.post(`/conversations/${conversationId}/leave_conversation`)
    .then(result => {
      saveHeaders(result.headers);
      return result;
    })
    .catch(res => handleRequestError(res, 'leaveConversation'));
};

export const getConversations = () => {
  return axios.get('/conversations')
    .then(res => {
      saveHeaders(res.headers);
      return res;
    })
    .catch(res => handleRequestError(res, 'getConversations'));
};

export const getConversation = (conversationId) => {
  return axios.get(`/conversations/${conversationId}`)
    .then(res => {
      saveHeaders(res.headers);
      return res;
    })
    .catch(res => handleRequestError(res, 'getConversation'));
};
