import React, { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Form,
  Modal,
  Message,
  Popup,
  Icon,
  Input,
  Image,
  Ref
} from 'semantic-ui-react';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import locationAutocomplete from 'services/locationAutocomplete';
import { uploadImage } from 'requests/aws';
import { createUser } from 'requests/users';
import styled from 'styled-components';
import Button from 'components/common/Button/ButtonSecondarySquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import HeadingThree from 'components/common/Textbox/HeadingThree';

const ModalContainer = styled.div`
  padding: 3rem 4rem 4rem 4rem;
  max-height: 80vh;
  background-color: white;
  color: black;
  overflow-y: scroll;
`;

const MessageNotice = styled(Message)`
  margin-bottom: 0em !important;
`;

const PopupStyle = {
  height: '80%',
  position: 'absolute',
  overflow: 'visible',
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)'
};

const formatAddress = (address, address2) => {
  return address2 ? `${address2}, ${address}` : address;
};

const NewSeeker = () => {
  let initialData = {
    email: '',
    first_name: '',
    last_name: '',
    description: '',
    admin_notes: '',
    services: [],
    offered_services: [],
    address: '',
    terms: false,
    recieve_notifications: false,
    preview: ''
  };
  const [data, setData] = useState(initialData);
  const { lat, lng } = useFetchLatLng(data.address);
  const locationSearchRef = useRef();
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);
  const handleChange = (e, { name, value }) => {
    setData({ ...data, error: null, [name]: value });
  };

  const handleAddressChange = () => {
    setTimeout(() => {
      setData(data => {
        return { ...data, error: null, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  const handleUpload = async (e, { name }) => {
    const file = e.target.files[0];
    setData({ ...data, [name]: file });
  };

  const exit = () => {
    setOpen(false);
    setData(initialData);
    setSuccess(false);
  };

  const handleSubmit = async () => {
    const {
      email,
      first_name,
      last_name,
      offered_services,
      admin_notes,
      address,
      address2,
      preview,
      recieve_notifications
    } = data;
    //if selected file, upload to S3 and get new image url, else default image url:
    let image =
      'https://neighborli-jc.s3.ca-central-1.amazonaws.com/development%2F60942d26-e3d8-4a54-b297-21d41bade0d7%2Fblank-avatar-300x300.png';
    if (data.preview) {
      const res = await uploadImage(preview);
      const parser = new DOMParser();
      const parsed = parser.parseFromString(res, 'text/xml');
      image = parsed.getElementsByTagName('Location')[0].childNodes[0]
        .nodeValue;
    }

    //Make post request
    createUser(
      {
        email,
        first_name,
        last_name,
        image,
        admin_notes,
        address: formatAddress(address, address2),
        recieve_notifications,
        lat,
        lng
      },
      offered_services
    )
      .then(user => {
        console.log(user);
        setSuccess(true);
      })
      .catch(function(error) {
        console.log(error.response);
        setData({ ...data, error: error.response.data });
      });
  };
  return (
    <>
      <Modal
        style={PopupStyle}
        onClose={exit}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon
        basic
        trigger={  (
          <Button>
            New Client
          </Button>
        )}
      >
        <ModalContainer>
          <a href="#" onClick={() => setOpen(false)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </a>
          <div className="flex flex-col items-center text-center mb-8">
            <HeadingThree>Register A New Seeker</HeadingThree>
          </div>

          <Form error={data.error ? true : false}>
            <Form.Input
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              value={data.email}
              onChange={handleChange}
              required
            />
            <Form.Input
              label="First Name"
              name="first_name"
              placeholder="First name"
              value={data.first_name}
              onChange={handleChange}
              required
            />
            <Form.Input
              label="Last Name"
              name="last_name"
              placeholder="Last name"
              value={data.last_name}
              onChange={handleChange}
              required
            />
            <Form.Field>
              <label>Notes</label>
              <small className="mb-1 block">These notes will only be visible to helpers and admins.</small>
              <Form.TextArea
                name="admin_notes"
                placeholder="Information about client, health concerns, ect."
                value={data.admin_notes}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Profile Image</label>
              {data.preview && (
                <Image
                  src={URL.createObjectURL(data.preview)}
                  size="medium"
                  alt="image to upload"
                />
              )}
              <Input
                name="preview"
                type="file"
                accept="image/*"
                onChange={handleUpload}
              />
            </Form.Field>
            <Ref innerRef={measuredRef}>
              <Form.Input
                label="Address"
                name="address"
                placeholder="Address"
                onChange={handleAddressChange}
                onBlur={handleAddressChange}
                loading={data.address && !lat ? true : false}
                required
              />
            </Ref>
            <Form.Input
              name="address2"
              placeholder="Appartment #, building, buzzer code"
              onChange={handleChange}
              value={data.address2}
            />
            
            <Form.Checkbox
              name="terms"
              checked={data.terms}
              onChange={() => {
                setData({ ...data, terms: !data.terms });
              }}
              label={
                <label>
                  Seeker agrees to the{' '}
                  <Link to={'/terms-and-conditions'}>Terms and Conditions</Link>
                  <Popup
                    content="Disclaimer:
                    To the maximum extent permitted by applicable law, 
                    we exclude all representations, warranties and conditions relating 
                    to our website and the use of this website."
                    trigger={<Icon color="grey" name="question circle outline" />}
                  />
                </label>
              }
              required
            />
            <Form.Checkbox
              name="recieve_notifications"
              checked={data.recieve_notifications}
              onChange={() => {
                setData({ ...data, recieve_notifications: !data.recieve_notifications });
              }}
              label={
                <label>
                  Seeker would like to hear about free services, special offers, and discounts as they are announced.  
                </label>
              }
            />
          </Form>
          {data.error && <MessageNotice
            error
            header="Registration error"
            content={data.error}
          />}
          {success && <MessageNotice positive>
            <Message.Header>Account Created</Message.Header>
            <p>
            The Account for {data.email} has sucessfully been created.
            </p>
          </MessageNotice>}
          <div className="flex justify-center mt-4">
            {success ?
              <Button onClick={exit} primary type="submit">
                Close
              </Button>
              :
              <Button onClick={handleSubmit} primary type="submit" disabled={!data.terms || !lat}>
                Submit
              </Button>
            }
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default NewSeeker;
