import React, { useState, useEffect, useContext, useCallback, useRef, useMemo } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Content from 'components/common/Content';
import queryString from 'query-string';
import { getServices } from 'requests/services';
import { searchHelperProfiles } from 'requests/helperProfiles';
import locationAutocomplete from 'services/locationAutocomplete';
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Circle,
  Popup
} from 'react-leaflet';
import { Dropdown, Form, Input, List, Ref } from 'semantic-ui-react';
import ListGroup from './common/listGroup';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import appContext from 'contexts/AppContext';
import SubTitleCard from 'components/common/SubTitleCard';

// const MobileFlex = styled.div`
//   @media all and ( min-width: 615px ) {
//     display: flex;
//   }
// `;

const maxSelectionLookup ={
  2: 3,
  3: 4,
  4: 5
};

const Helpers = () => {
  const { currentUser } = useContext(appContext);
  const location = useLocation();
  const history = useHistory();
  const search = useMemo(() => queryString.parse(location.search, { arrayFormat: 'comma' }), [location.search]);
  //state data setup
  const initialData = {
    helpers: [],
    filtered_helpers: [],
    center: [49.28, -123.12],
    services: [],
    duration: Number(search.duration),
    address: search.location,
    offered_services: [],
    radius: 3
  };
  const [data, setData] = useState(initialData);
  const locationSearchRef = useRef();
  const { lat, lng } = useFetchLatLng(data.address);
  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);

  //fetch data once on page load
  useEffect(() => {
    //get list of services available
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: Number(item.id) };
        });
        const offered_services = search.help ? services.reduce((acc, service) => search.help.includes(service.text) || search.help.includes(service.value.toString()) ? [...acc, service.value] : acc, []) : [];
        setData(data => {
          return {
            ...data,
            services,
            offered_services
          };
        });
      });
  }, [search.help]);

  //set default map center to user location
  useEffect(() => {
    currentUser && !data.address &&
      setData(data => {
        return {
          ...data,
          address: currentUser.address,
          center: [currentUser.lat, currentUser.lng]
        };
      });
  }, [currentUser, data.address]);
  //sends search request to backend whenever lat/lng/radius/services updates
  useEffect(() => {
    //only send search request if there's an address or selected services
    if (lat || data.offered_services.length) {
      searchHelperProfiles({
        lat,
        lng,
        radius: data.radius,
        offered_services: data.offered_services
      })
        .then(helperProfiles => {
          // ** CHANGE to selector
          helperProfiles.map((user) => {
            user.services = user.helper_profile.services;
            user.availability = user.helper_profile.availability;
            user.about = user.helper_profile.about;
            user.how_can_i_help = user.helper_profile.how_can_i_help;
            user.hobbies = user.helper_profile.hobbies;
            user.languages = user.helper_profile.languages;
            return user;
          });
          setData(data => {
            return {
              ...data,
              filtered_helpers: helperProfiles,
              center: lat ? [lat, lng] : data.center
            };
          });
        });
    }
  }, [lat, lng, data.radius, data.offered_services]);

  //reset list if no address or offered_services
  useEffect(() => {
    if (!lat && !data.offered_services.length) {
      setData(data => {
        return {
          ...data,
          filtered_helpers: data.helpers
        };
      });
    }
  }, [lat, data.offered_services.length]);
  //callback function for resetting center - to be called when clicking on user cards and markers
  const reposition = (lat, lng) => {
    setData(data => {
      return {
        ...data,
        center: [lat, lng]
      };
    });
  };
  //callback function for clicking on map marker
  const clickMarker = event => {
    const { lat, lng } = event.latlng;
    reposition(lat, lng);
  };

  const setAddress = () => {
    setTimeout(() => {
      history.push({
        pathname: '/helpers',
        search: '?' + new URLSearchParams({ location: locationSearchRef.current.value }).toString()
      });
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  //callback function for form changes
  const handleChange = (e, { name, value }) => {
    setData(data => {
      return { ...data, [name]: value };
    });
  };

  const setHelp= (e, { value }) => {
    if ((maxSelectionLookup[data.duration] || 3) < value.length) {
      value.shift();
    }
    setData({ ...data, offered_services: value });
  };
  //search radius circle
  const searchRadius = (
    <Circle
      key="searchRadius"
      center={
        (lat && [lat, lng]) ||
        (currentUser && [currentUser.lat, currentUser.lng]) || [0,0]
      }
      radius={data.radius * 1000}
      fillOpacity={0.1}
    />
  );

  //making circles for all helpers
  const circles = data.filtered_helpers.map(helper => (
    <Circle
      onClick={clickMarker}
      key={helper.id}
      center={[helper.lat, helper.lng]}
      radius={100}
      fillOpacity={0.6}
      color="purple"
      stroke={false}
    >
      <Popup>
        <Link to={`./helpers/${helper.id}`}>
          <h5 className="card-title">{helper.first_name}</h5>
        </Link>
        <img
          src={helper.image}
          className="card-img img-fluid height:auto thumbnail"
          alt="..."
        />
        {helper.services &&
          helper.services.map(service => (
            <span key={service.id} className="m-1 p-1 badge badge-success">
              {service.name}
            </span>
          ))}
      </Popup>
    </Circle>
  ));

  return (
    <Content padding>
      <div className="flex flex-col">
        <div>
          <SubTitleCard>Check out Helpers Near You</SubTitleCard>
          <p>
            All our Helpers are family just like you: children, parents and partners with loved ones they care for. Many are post-secondary 
            students representing many different areas of study including healthcare, community programs, 
            business, law, tourism, hospitality, environmental stewardship, arts, sciences and others. 
            Rest assured our Helpers have undergone: Background checks, reference checks, interviews, vetting process, 
            risk management and safety training, emergency protocols and awareness training in working with 
            elders and cognitive impairments. 
          </p>
          <p>
            Allow our Helpers to be the best they can be. Many are volunteers with big hearts, wishing to 
            make a meaninful intergenerational connection nearby, share stories, experiences and help out 
            where they can. Some will need to charge as we know many around us need financial help too, and we believe 
            in supporting our communities through providing flexible, valuable work, connectivity and learning experiences.
          </p>
        </div>
        <div>
          <SubTitleCard>How to Get Started: </SubTitleCard>   
          <List as='ol'>
            <List.Item as='li'><a href="https://www.choro.ca/login">Log in</a> or <a href="https://www.choro.ca/users/new">Sign Up </a> 
            if you are new to the site (for security of our community, you must be logged in to communicate with our Helpers).</List.Item>
            <List.Item as='li'>Type in the address where the helper is going to visit.</List.Item>
            <List.Item as='li'>Select the kinds of help you need.</List.Item>
            <List.Item as='li'>Click on the name of the person you would like to connect with.</List.Item>
            <List.Item as='li'>Click on Chat to confirm their availability and details.</List.Item>
            <List.Item as='li'>Book your Helper and wait for help to arrive soon!</List.Item>
          </List>
        </div>
        <br/>
        <br/>
        <div>
          <Form className="mb-3">
            <Form.Group widths="equal">
              <Ref innerRef={measuredRef}>
                <Form.Input
                  action={
                    <Dropdown text={`${data.radius} KM`} button basic floating>
                      <Dropdown.Menu>
                        <Input
                          name="radius"
                          className="search-radius"
                          onChange={handleChange}
                          type="range"
                          min={1}
                          max={10}
                          step={1}
                          defaultValue={3}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                  label={'Address'}
                  name="address"
                  placeholder={search.location || 'Enter your location to search within'}
                  onChange={setAddress}
                  onBlur={setAddress}
                  icon="search"
                  iconPosition="left"
                />
              </Ref>
              {/* <Form.Field>
            <Dropdown
              name="duration"
              placeholder="How long?"
              fluid
              selection
              value={data.duration}
              onChange={handleChange}
              options={hourOptions}
            />
          </Form.Field> */}
              <Form.Field >
                <label>Select Services</label>
                <Dropdown
                  name="offered_services"
                  placeholder="Filter by type of help"
                  fluid
                  multiple
                  search
                  selection
                  value={data.offered_services}
                  onChange={setHelp}
                  options={data.services}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        <div className="flex sm:flex-col gap-2">
          <div className="w-1/2 sm:w-1/1">
            <ListGroup
              items={data.filtered_helpers}
              handleClick={reposition}
              type="helpers"
            />
          </div>
          <div className="w-1/2 sm:w-1/1">
            <LeafletMap
              center={data.center}
              zoom={14}
              maxZoom={20}
              attributionControl={true}
              zoomControl={true}
              doubleClickZoom={true}
              scrollWheelZoom={true}
              dragging={true}
              animate={true}
              easeLinearity={0.35}
            >
              <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
              {currentUser && currentUser.lat && (
                <Marker
                  onClick={clickMarker}
                  position={[currentUser.lat, currentUser.lng]}
                >
                  <Popup>
                    <h5>
                      <span role="img" aria-label="home">
                      🏠
                      </span>
                    Your address is:
                    </h5>
                    {currentUser.address}
                  </Popup>
                </Marker>
              )}
              {lat && lng && (
                <Marker onClick={clickMarker}  position={[lat, lng]}>
                  <Popup>
                    <h5>The address you are searching for is:</h5>
                    {data.address}
                  </Popup>
                </Marker>
              )}
              {lat && searchRadius}
              {circles}
            </LeafletMap>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Helpers;
