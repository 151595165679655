import React from 'react';
import { Segment } from 'semantic-ui-react';

const ServicesSegment = ({ services, type }) => {
  const badges =
    services &&
    services.map(service => (
      <div key={service.id} className="m-1 p-1 badge badge-success">
        {service.name}
      </div>
    ));
  const segment =
    type === 'user' ? (
      <></>
    ) : (
      <Segment>
        <h6>Services:</h6>
        {badges}
      </Segment>
    );
  return segment;
};

export default ServicesSegment;
