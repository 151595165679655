import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';


export default function RatingStar({ setRatingInfo, ratingInfo, setRating, position, large, staticStar, show }) {
  const starFilled = (position) => {
    if (staticStar) return show ? faStar : farStar;
    const filled = ratingInfo.ratingHover ? ratingInfo.ratingHover >= position : ratingInfo.rating >= position;
    return filled ? faStar : farStar;
  };

  return (

    <FontAwesomeIcon
      style={{ cursor: staticStar ? 'auto' : 'pointer' }}
      size={large ? '2x' : '1x'}
      color="#212529"
      onMouseEnter={() => !staticStar && setRatingInfo({ ...ratingInfo, ratingHover: position })}
      onMouseLeave={() => !staticStar && setRatingInfo({ ...ratingInfo, ratingHover: null })}
      icon={starFilled(position)}
      onClick={() => !staticStar && setRating(position)}
    />
  );
}
