import React, { useState, useEffect, useContext } from 'react';
import { adminGetUsers } from 'requests/admin';
import useUser from 'hooks/useUser';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import appContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { startOfWeek } from 'helpers/calendar';

const UserDropdown = styled(Dropdown)`
  .search {
    min-width: 6rem;
  }
  .avatar {
    width: 2rem !important;
  }
  .ui.avatar.image {
    width: 2rem !important;
  }
  .sizer {
    color: transparent;
  }
`;

const checkAvailable = (availability, schedule) => {
  if (availability && schedule && schedule.availability.length > 0) {
    for (let block of availability) {
      for (let scheduleBlock of schedule.availability) {
        let scheduleBlockStart = startOfWeek(new Date(block[0])).getTime() + scheduleBlock[0] * 60000;
        let scheduleBlockEnd = startOfWeek(new Date(block[0])).getTime() + scheduleBlock[1] * 60000;
        if (
          (block[0] <= scheduleBlockEnd && block[0] >= scheduleBlockStart) || 
          (block[0] <= scheduleBlockStart && block[1] >= scheduleBlockEnd) ||
          (block[1] <= scheduleBlockEnd && block[1] >= scheduleBlockStart)) 
        {
          return (<FontAwesomeIcon className="text-logo" icon={faCheckSquare} />);
        }
      }
    }
  }
  return false;
};


const UsersSelect = ({ passSelected, filters, multiple, availability }) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const { user } = useUser(selectedUser);
  const { adminLocation } = useContext(appContext);


  useEffect(() => {
    let params = adminLocation ? { ...filters, location: adminLocation } : filters;
    adminGetUsers(null, params)
      .then(users => {
        setUsers(users.map(user => (
          { 
            text: `${user.first_name} ${user.last_name}`, 
            value: user, 
            key: user.id,
            description: checkAvailable(availability, user.weekly_schedule),
            image: { avatar: true, src: user.image } 
          })));
      });
  }, [adminLocation, availability]);

  useEffect(() => {
    if (availability) {
      setUsers(users.map(user => ({ ...user, description: checkAvailable(availability, user.weekly_schedule) })));
    }
  }, [availability]);

  useEffect(() => {
    if (user && !multiple) {
      passSelected && passSelected(user);
    }
  }, [user]);


  const handleChange = (e, { value }) => {
    if (multiple) {
      passSelected(value);
    } else {
      setSelectedUser(value.id);
    }
  };

  return (
    <div>
      <UserDropdown
        button
        options={users}
        multiple={multiple}
        search
        onChange={handleChange}
        placeholder={filters && filters.type == 'helper' ? 'Search helpers' : 'Search users'}
      />
    </div>
  );
};

export default UsersSelect;
