import React from 'react';
import PrivacyTerms from '../../components/Terms/PrivacyTerms';

const Privacy = () => {
  return (
    <PrivacyTerms/>
  );
};

export default Privacy;
