import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import NotAdmin from './NotAdmin';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import Table from 'components/common/Table';
import HelperAppllicationStatus from 'components/common/HelperApplicationStatus';
import { adminGetUsers } from 'requests/admin';
import UsersSearch from 'components/Admin/UsersSearch';
import useFilters from 'hooks/useFilters';
import LocationPicker  from 'components/Locations/LocationPicker';

const sortLookup = {
  Name: 'first_name',
  Date: 'helper_applications.created_at',
  Status: 'helper_applications.status'
};

const headers = ['Name', 'Date', 'Status'];

const filterDefaults = () => {
  return ({
    helper_application_status: {
      'nil': { value: true, name: 'pending' }, 
      'approved': { value: true, name: 'approved' }, 
      'declined': { value: true, name: 'declined' } 
    }
  });
};

const Applications = () => {
  const { currentUser } = useContext(appContext);
  const { items, filterOptions } = useFilters(filterDefaults(),  { item: 'Date', direction: 'desc' }, {}, sortLookup, (filters) => adminGetUsers(true, filters));
  let rows = items && items.map((user, index) => [
    (<Link key={index} style={{ color: '#33c18f' }} to={`/admin/users/${user.id}/helper_application/${user.helper_application.id}`}>
      {user.first_name} {user.last_name}
    </Link>),
    (new Date(user.helper_application.created_at)).toDateString(),
    <HelperAppllicationStatus key={index} status={user.helper_application.status}/>
  ]
  );

  if (!rows || rows.length < 1) {
    rows = [];
    rows.push([<p key={9999}>There are currently no helper applications. Invite helpers to join <a href="/helpers/new">here</a></p>]);
  } else {
    rows.push([<p key={9999}>Invite helpers to join <a href="/helpers/new">here</a></p>]);
  }


  const Panel = 
    <>
      <UsersSearch/>
      <br></br>
      <SubTitleCard>Pending helper applications <LocationPicker/></SubTitleCard>
      <Table rows={rows} headers={headers} filterOptions={filterOptions} />
    </>;

  return (
    <Content padding>
      {currentUser && currentUser.type === 'Admin' ?
        Panel : <NotAdmin/> }
    </Content>
  );
};

export default Applications;

