import React from 'react';
import styled, { css } from 'styled-components';

const OptionDiv = styled.div`
  padding: .79rem 1.14rem;
  line-height: 0.8em;
  font-size: 0.8rem;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    color: rgba(0,0,0,.95);
    background: rgba(0,0,0,.06);
  }
  ${props => props.selected &&
    css`
    color: rgba(0,0,0,.95);
    background: rgba(0,0,0,.03);
    `
}
`;

export default function Option({ option, highlighted, onSelect }) {
  return (
    <OptionDiv
      role="option"
      selected={highlighted && highlighted.value == option.value}
      onMouseDown={() => onSelect(option)}
    >
      {option.text}
    </OptionDiv>
  );
}
