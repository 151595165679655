import React from 'react';
import ButtonRoot from './Shared/ButtonRoot';

const ButtonPrimarySquare = (props) => {
  return (
    <ButtonRoot 
      className="
        border-2 border-text-gray-80
        text-text-gray-80
        font-semibold 
        rounded 
        hover:no-underline
        hover:text-black
      "
      {...props}
    />
  );
};

export default ButtonPrimarySquare;
