import React from 'react'; 

const HeadingFour = ({ children }) => {
  return (
    <h4 className="
      font-semibold 
      text-2xl
      leading-7
      my-0
      "
    >
      {children}
    </h4>
  );
};

export default HeadingFour;
