import React from 'react'; 

const TextBodyReg = ({ children }) => {
  return (
    <p className="
      md:text-base" 
    >
      {children}
    </p>
  );
};

export default TextBodyReg;
