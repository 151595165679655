import React, { useState } from 'react';
import { Card } from 'semantic-ui-react';
import RatingStar from './RatingStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit as farEdit } from '@fortawesome/free-regular-svg-icons';
import { reviewBooking } from 'requests/bookings';


export default function BookingRating({ booking, updateBookingInfo }) {
  const [ratingInfo, setRatingInfo] = useState({
    showRatingQuestion: !booking.rating,
    rating: null, 
    ratingHover: null
  });

  const updateRating = (rating) => {
    setRatingInfo({ ...ratingInfo, rating });
    reviewBooking(booking, rating)
      .then(status => {
        setRatingInfo((current) => ({ ...current, showRatingQuestion: false }));
        updateBookingInfo({ status, rating });
      });
  };

  return (
    <div>
      {ratingInfo.showRatingQuestion &&
                <Card>
                  <Card.Content>
                    <Card.Header> How would you rate the service you received?</Card.Header>
                    <Card.Meta>This rating helps us provide the best service.</Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <RatingStar
                      large
                      setRatingInfo={setRatingInfo} 
                      setRating={updateRating} 
                      ratingInfo={ratingInfo} position={1}
                    />
                    <RatingStar
                      large
                      setRatingInfo={setRatingInfo} 
                      setRating={updateRating} 
                      ratingInfo={ratingInfo} position={2}
                    />    
                    <RatingStar
                      large
                      setRatingInfo={setRatingInfo} 
                      setRating={updateRating} 
                      ratingInfo={ratingInfo} position={3}
                    />    
                    <RatingStar
                      large
                      setRatingInfo={setRatingInfo} 
                      setRating={updateRating} 
                      ratingInfo={ratingInfo} position={4}
                    />    
                    <RatingStar
                      large
                      setRatingInfo={setRatingInfo} 
                      setRating={updateRating} 
                      ratingInfo={ratingInfo} position={5}
                    />      
                  </Card.Content>
                </Card>
      }
      {
        booking.rating && !ratingInfo.showRatingQuestion &&
                <h5>
                    Rating: 
                  <RatingStar 
                    staticStar
                    show={booking.rating >= 1}
                  />
                  <RatingStar 
                    staticStar
                    show={booking.rating >= 2}
                  />
                  <RatingStar 
                    staticStar
                    show={booking.rating >= 3}
                  />
                  <RatingStar 
                    staticStar
                    show={booking.rating >= 4}
                  />
                  <RatingStar 
                    staticStar
                    show={booking.rating >= 5}
                  />
                  <FontAwesomeIcon 
                    icon={farEdit}
                    size="sm"
                    style={{ color: '#2185d0', cursor: 'pointer', marginLeft: '6px' }}
                    onClick={() => setRatingInfo({ ...ratingInfo, showRatingQuestion: true })}
                  />
                </h5>
      }
    </div>
  );
}
