import React, { useEffect, useState, useContext } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { adminGetLocations } from 'requests/admin';
import appContext from 'contexts/AppContext';

export default function LocationPicker() {
  const { adminLocation, setAdminLocation } = useContext(appContext);
  const [location, setLocation] = useState(adminLocation);
  const [locations, setLocations] = useState(['Vancouver', 'Victoria']);

  useEffect(() => {
    adminGetLocations()
      .then(locations => {
        if (locations.length < 2) {
          setAdminLocation(null);
        }
        let newLocations = locations.map(location => ({ text: location.name, value: location.id, key: location.id }));
        setLocations([...newLocations, { text: 'All Locations', value: 'all', key: 'all' }]);
      });
  }, []);

  const handleChange = (e, { value }) => {
    setLocation(value);
    setAdminLocation(value);
  };
  
  return (
    <div className={locations && locations.length > 2 ? '' : 'hidden'}>
      <Dropdown
        className="min-w-0"
        button
        options={locations}
        selection
        value={location}
        onChange={handleChange}
        placeholder='Set Location'
      />
    </div>
  );
}
