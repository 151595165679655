import React from 'react';
import { Form } from 'semantic-ui-react';

export default function TableFilters({ filters, setFilters }) {
  return (
    <tr className="sticky background bg-ui-gray-5 top-20">
      {Object.entries(filters).map(([category, options]) => (
        <td colSpan="100%" key={category} className="w-full p-4 border-b border-primary">
          <strong>{category.split('_').join(' ')}</strong>
          <div className="flex gap-3 flex-wrap mt-2">
            {Object.entries(options).map(([optionName, optionsInfo]) => {
              return (
                <div key={optionName} className="flex">
                  <Form.Checkbox 
                    name={optionsInfo.name}
                    checked={optionsInfo.value}
                    onChange={() => setFilters((current) => {
                      return {
                        ...current, 
                        [category]: {
                          ...current[category], 
                          [optionName]: { ...optionsInfo, value: !optionsInfo.value }
                        }
                      };
                    })
                    } 
                  />
                  <label className="ml-1">
                    {optionsInfo.name}
                  </label>
                </div>
              );
            })}
          </div>
        </td>
      ))}
    </tr>
  );
}
