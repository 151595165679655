import React from 'react'; 

const HeadingFive = ({ children }) => {
  return (
    <h5 className="
      font-semibold 
      text-lg
      leading-6
      my-0
      "
    >
      {children}
    </h5>
  );
};

export default HeadingFive;
