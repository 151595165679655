import React from 'react';
import HeadingSix from 'components/common/Textbox/HeadingSix';
import ServiceRequestStatus from 'components/common/ServiceRequestStatus';
import { readableDate } from 'helpers/general';

export default function RequestResponses({ booking }) {
  return (
    <>
      {booking.service_requests && booking.service_requests.map((request, index) => (
        <div key={index} className="p-4 border-2 border-ui-gray-80 flex justify-between">
          <div>
            <HeadingSix>{request.helper.first_name}</HeadingSix>
            <div className="mt-2">
              <HeadingSix>Details:</HeadingSix>
              <p>{ request.reason_declined || (request.availability && readableDate(request.availability)) || '-'}</p>
            </div>
          </div>
          <div className="flex flex-row">
            <HeadingSix>Status:&nbsp;</HeadingSix> <ServiceRequestStatus request={request}/>
          </div>
        </div>
      ))}
    </>
  );
}
