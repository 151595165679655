import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function ServiceRequestStatus({ request }) {
  if(request.availability) {
    return (<span className="text-primary"> <FontAwesomeIcon icon={faCheckSquare} /> Accepted</span>);
  } else if(request.reason_declined) {
    return (<span className="text-danger"><FontAwesomeIcon icon={faTimes} /> Declined</span>);
  } else {
    return (<span><FontAwesomeIcon icon={faClock} /> No Response</span>);
  }
}
