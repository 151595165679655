import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Modal, Form, Message, Ref, Popup, Icon, Loader, Button } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import locationAutocomplete from 'services/locationAutocomplete';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { getServices } from 'requests/services';
import { updateBooking } from 'requests/bookings';
import { estimate } from 'requests/payments';
import Dropdown from 'components/common/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const PopupFont = {
  fontFamily: '\'Montserrat\', -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif'
};

Moment.locale('en');
momentLocalizer();

function EditBooking({ booking }) {
  const { currentUser } = useContext(appContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [creationError, setCreationError] = useState(false);
  const [helpOptions, setHelpOptions] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const locationSearchRef = useRef();
  const [errors, setErrors] = useState({
    name_of_client: null,
    address: null,
    phone_number: null,
    date: null,
    email: null,
    checkboxError: null,
    help_needed: null,
    additional_info: null
  });

  const past = new Date(booking.date) < new Date();

  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
      input.value = booking.address;
    }
  }, [booking]);
  
  useEffect(() => {
    if (booking && booking.services) {
      estimate(booking.services.split(/,\s*/))
        .then(cost => {
          setData(currentData => ({ ...currentData, cost }));
        });
      setData({
        id: booking.id,
        date: new Date(booking.date),
        name_of_client: (booking.name_of_client),
        address: (booking.address),
        phone_number: booking.phone_number,
        email: (booking.email),
        emergency_contact: {
          name: booking.emergency_contact && booking.emergency_contact.name,
          relation: booking.emergency_contact && booking.emergency_contact.relation, 
          phone_number: booking.emergency_contact && booking.emergency_contact.phone_number
        },
        help_needed: booking.services ? booking.services.split(/,\s*/) : [],
        additional_info: booking.additional_info,
        cost: 25.99
      });
    }
  }, [booking]);

  useEffect(() => {
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.name };
        });
        setHelpOptions(services);
      });
  }, []);

  const checkError = (update = true) => {
    let newErrors = { ...errors };
    let error = false;
    for (const label in data) {
      if ((!data[label] || (Array.isArray(data[label]) && data[label].length === 0)) && Object.keys(errors).includes(label)) {
        newErrors[label] =  'Cannot be left blank';
        error = true;
      } else {
        newErrors[label] = null;
      }
    }
    update && setErrors(newErrors);
    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { help_needed } = data;
    if (checkError()) return;
    const services = help_needed.join(', ');
    setWaiting(true);
    setCreationError(false);
    updateBooking({ 
      ...data, 
      services
    })
      .then(() => {
        window.location.href = `/bookings/${booking.id}`;
      })
      .catch(error => {
        console.log(error);
        setWaiting(false);
        setCreationError(true);
      });
  };

  const handleChange = (e, type) => {
    let { name, value } = e.target;
    if (!name && !value) {
      name = type.name;
      value = type.value;
    }
    if (type === 'emergency') {
      setData({ ...data, emergency_contact: { ...data.emergency_contact, [name]: value } });
    } else {
      setData({ ...data, [name]: value });
    }
    errors[name] && setErrors({ ...errors, [name]: null });
  };

  const handleAddressChange = () => {
    setTimeout(() => {
      setErrors({ ...errors, address: null });
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  const changeHelpOptions = (value) => {
    estimate(value)
      .then(cost => {
        setData({ ...data, help_needed: value, cost });
      });
  };

  const handleDateChange = (date) => {
    if (Moment(date).isBefore(Moment())) {
      setData({ ...data, date: undefined });
      setErrors({ ...errors, date: 'You cannot select dates in the past' });
    } else {
      setData({ ...data, date });
      setErrors({ ...errors, date: null });
    }
  };

  const openModal = () => {
    if (!currentUser) {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to book help.',
          from: location.pathname
        }
      });
    } else {
      setOpen(true);
    }
  };

  const canEdit = () => {
    if (!past) {
      if (currentUser.type == 'Admin' || (currentUser.type == 'Helper' &&  booking.user_id == currentUser.id)) {
        return true;
      } else if (currentUser.type == 'Seeker' && booking.seeker_user_id == currentUser.id && booking.status == 'pending') {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      {canEdit() && <Popup
        trigger={   
          <span className="cursor-pointer" onClick={() => setOpen(!open)}>
            <FontAwesomeIcon icon={faEdit}/>
          </span>} 
        size={'mini'}
        wide={'very'}
        position='top center'
      >
        Edit
      </Popup>}
      <Modal
        onClose={() => setOpen(false)}
        onOpen={openModal}
        open={open}
        closeIcon
      >
        <Modal.Header style={PopupFont}>
          Edit Booking
        </Modal.Header>
        <Modal.Content image scrolling>
          <Form>
            <Form.Field required error={errors.date}>
              <label>Booking date</label>
              <DateTimePicker
                onChange={handleDateChange}
                value={data.date}
                name="date"
                error={errors.date}
                required
              />
              {errors.date && 
                <Message 
                  negative 
                  size="tiny"
                  style={{ padding: '7px', display: 'inline-block' }}
                >
                  <strong>{errors.date}</strong>
                </Message>
              }
            </Form.Field>
            <Form.Field required style={{ 'padding-top': '10px' }}>
              <label>
                Client Name
              </label>
              <Form.Input
                name="name_of_client"
                placeholder="Name of client"
                value={data.name_of_client}
                error={errors.name_of_client}
                onChange={(e) => {handleChange(e);}}
                required
              />
            </Form.Field>
            <Ref innerRef={measuredRef}>
              <Form.Field required>
                <label>
                  Client Address
                </label>
                <Form.Input
                  name="address"
                  placeholder="Address of client"
                  onChange={handleAddressChange}
                  onBlur={handleAddressChange}
                  error={errors.address}
                  required
                />
              </Form.Field>
            </Ref>
            <Form.Field required>
              <label>
                Client Phone number
              </label>
              <Form.Input
                name="phone_number"
                placeholder="Phone number of client"
                value={data.phone_number}
                error={errors.phone_number}
                onChange={(e) => {handleChange(e);}}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Emergency Contact Info</label>
              <Form.Input
                name="name"
                placeholder="Name of emergency contact"
                value={data.emergency_contact && data.emergency_contact.name}
                onChange={(e) => {handleChange(e, 'emergency');}}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                name="phone_number"
                placeholder="Phone number of emergency contact"
                value={data.emergency_contact && data.emergency_contact.phone_number}
                onChange={(e) => {handleChange(e, 'emergency');}}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                name="relation"
                placeholder="Relation to emergency contact"
                value={data.emergency_contact && data.emergency_contact.relation}
                onChange={(e) => {handleChange(e, 'emergency');}}
              />
            </Form.Field>
            <Form.Field   required>
              <label>Select the type(s) of help needed</label>
              <Dropdown
                value={data.help_needed}
                error={errors.help_needed}
                name="help_needed"
                onChange={changeHelpOptions} 
                options={helpOptions} 
              />
            </Form.Field>
            <Form.Field required>
              <label>
                { data.help_needed && data.help_needed.length !== 0 ? `Additional information about ${data.help_needed.join(', ')}` : 'Additional Information'}
                <Popup
                  trigger={<Icon color="grey" name="question circle outline" />}
                  size={'small'}
                  wide={'very'}
                  position='top center'
                  flowing
                >
                  <p><strong>Provide the following information for:</strong></p>
                  <p><strong>Light Cleaning</strong></p>
                  <ul className="list-disc ml-5">
                    <li>
                    Size of home
                    </li>
                    <li>
                    Confirm all cleaning supplies, equipment and cleaning solutions are on site
                    </li>
                    <li>
                    Exactly what needs cleaning: vaccuum livingroom, 1 bathroom, mopping kitchen, etc.
                    </li>
                    <li>
                    NOTE: &apos;light housekeeping&apos; and senior home help only. &nbsp;Helpers are not professional cleaners &amp; do not do extensive cleaning.&nbsp;
                    </li>
                  </ul>
                  <p><strong>Driving</strong></p>
                  <ul className="list-disc ml-5">
                    <li>
                    Pickup Drop Off locations
                    </li>
                    <li>
                    Client needs to pay mileage of 55 cents per km and parking (if any)
                    </li>
                  </ul>
                  <p><strong>Errands</strong></p>
                  <ul className="list-disc ml-5">
                    <li>
                    Location and exact items&nbsp;
                    </li>
                    <li>
                    Ensure <strong>Pre-Paid</strong>, to save money and avoid extra 5% platform charge
                    </li>
                    <li>
                    Client needs to pay 55 cents per km plus parking
                    </li>
                  </ul>
                  <p><strong>Gardening</strong></p>
                  <ul className="list-disc ml-5">
                    <li>
                    Exactly what tasks are required
                    </li>
                    <li>
                    All garden tools, supplies, plantings are on site
                    </li>
                    <li>
                    Small tasks only. &nbsp;Helpers are not professional gardeners or landscapers
                    </li>
                  </ul>
                  <p><strong>Tech Help</strong></p>
                  <ul className="list-disc ml-5">
                    <li>
                    Confirm brand and make of computer, mobile phone, tablet, TV or remote.&nbsp;
                    </li>
                    <li>
                    Confirm exactly what issues or help is needed
                    </li>
                  </ul>
                </Popup>
                <br></br>
                <small> Extra task details and any health concerns . . .</small>
              </label>
              <Form.TextArea
                name="additional_info"
                placeholder="Additional information..."
                value={data.additional_info}
                error={errors.additional_info}
                onChange={(e) => {handleChange(e, 'additional_info');}}/>
            </Form.Field>
            <Form.Field className="pb-6">
              <label>Cost</label>
              <Message info size="tiny">
                <p>${data.cost}</p>
              </Message>
            </Form.Field>
          </Form>
        </Modal.Content>
        {creationError && <div className="relative h-0 -top-20">
          <Message negative>
            <Message.Header>Something went wrong</Message.Header>
            <p>We could not create your booking. Please Try again.</p>
          </Message>
        </div>}
        <Modal.Actions>
          <Popup
            trigger={ 
              <span>
                <Button
                  small
                  onClick={handleSubmit}
                  disabled={waiting || checkError(false)}
                  type="submit"
                >
                  { waiting ? <><Loader size="small"/>&nbsp;</> : 'Submit'}
                </Button>
              </span>}
            disabled={!checkError(false)}
            size={'mini'}
            wide={'very'}
            position='top center'
          >
            { waiting ? 'Creating booking' : 'Please fill out All required fields' }
          </Popup>
          <Button onClick={() => setOpen(false)} small="true">
          Close
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default EditBooking;
