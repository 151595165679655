import { useState, useEffect } from 'react';
import useDebounce from './useDebounce';
import { getLatLng } from 'requests/google';

export default function useFetchLatLng(value) {
  const debouncedAddress = useDebounce(value, 500);
  const [data, setData] = useState({ lat: null, lng: null });
  useEffect(() => {
    if (debouncedAddress) {
      getLatLng(debouncedAddress)
        .then(setData)
        .catch(console.log);
    }
  }, [debouncedAddress]);
  return data;
}
