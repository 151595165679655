import React from 'react';
import { PageMargined } from 'components/common/Layout';
import { SecondaryHeading, HeadingFive, TextBodyReg } from 'components/common/Textbox';

const PrivacyTerms = () => {
  return (
    <PageMargined>
      <div className="
        sm:py-6
        md:py-24
        "
      >
        <SecondaryHeading>Choro Privacy Policy</SecondaryHeading>
        <br />
        <TextBodyReg>
          This Privacy Policy is intended to help you better understand how we
          collect, use and store your personal information—whether you are a
          helper offering to help others or a seeker looking for help from the
          Choro website, or whether you’re simply visiting this website. By
          using any of Choro’s services, or by dealing with a helper or
          seeker who has posted on Choro’s website, you are agreeing to the
          terms of this Privacy Policy.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          {' '}
          We may update this Privacy Policy from time to time in order to reflect,
          for example, changes to our privacy practices or for other operational,
          legal, or regulatory reasons. If we make material changes to this
          Privacy Policy, we will give you notice of such changes by posting the
          revised policy on this website, and where appropriate, by other means.
          By continuing to use this website after these changes are posted, you
          agree to the revised policy.
        </TextBodyReg>
        <br />
        <HeadingFive>What information do we collect from Users and why?</HeadingFive>
        <TextBodyReg>
          We collect your name, phone number(s), address, email address, photo,
          descriptions of your background, skills, previous experience, services
          offered, services required, and references directly from you. • We use
          this information to work with you, confirm your identity, contact you,
          transfer money to you or from you if necessary and to screen for risk,
          fraud, inappropriateness of services or post or other similar types of
          issues. • We also collect this information from you so that you can set
          up a profile on the website and connect with individuals nearby who are
          viewing, offering or seeking help.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          We collect data about how and when you access your account and the
          Choro platform, including information about the device and browser
          you use, your network connection, your IP address, and information about
          how you browse through the Choro interface. • We use this
          information to give you access to and improve our website and services;
          for example, to make our platform interface easier to use and navigate.
          We also use this information to improve the types of services to better
          meet the needs of users.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          We do not collect credit card information. Instead, we use a third-party
          service that will collect credit card information and other financial
          information. You will need to refer to their Privacy Policy for
          information in this regard.{' '}
        </TextBodyReg>
        <br />
        <TextBodyReg>
          We will also use personal information in other cases where you have
          given us express permission.
        </TextBodyReg>
        <br />
        <HeadingFive>When do we collect this information?</HeadingFive>
        <TextBodyReg>
          We collect this information when you login to create an account, when
          you sign up as a Helper, or when you sign up as a Seeker. We also
          collect any additional information that you might provide to us.
        </TextBodyReg>
        <br />
        <HeadingFive>How long do we retain your personal information?</HeadingFive>
        <TextBodyReg>
          In general, we keep your personal information throughout your
          relationship with us. For Helpers or Seekers, this means we will keep
          your information as long as you maintain a profile on our platform. For
          others, this means we will keep your information until you inform us
          that you wish to terminate your relationship with us. We purge personal
          information 90 days after receiving a deletion request. We also purge
          personal information from profiles 90 days after two years of
          inactivity.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          Once you terminate your relationship with us, we generally will continue
          to store archived copies of your personal information for legitimate
          business purposes such as to defend a contractual claim or for audit
          purposes and to comply with the law, except when we receive a valid
          erasure request.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          We will continue to store anonymous or anonymized information, such as
          website visits, without identifiers, in order to improve our services.
        </TextBodyReg>
        <br />
        <HeadingFive>What we don’t do with your personal information</HeadingFive>
        <TextBodyReg>
          We do not and will never share, disclose, sell, rent, or otherwise
          provide personal information to other companies for the marketing of
          their own products or services.{' '}
        </TextBodyReg>
        <br />
        <HeadingFive>How do we keep your personal information secure?</HeadingFive>
        <TextBodyReg>
          We follow industry standards on information security management to
          safeguard sensitive information, such as financial information,
          intellectual property, employee details and any other personal
          information entrusted to us. Our information security systems apply to
          people, processes and information technology systems on a risk
          management basis.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          No method of transmission over the Internet, or method of electronic
          storage, is 100% secure. Therefore, we cannot guarantee the absolute
          security of your personal information.
        </TextBodyReg>
        <br />
        <HeadingFive>Control over and access to your personal information</HeadingFive>
        <TextBodyReg>
          Choro understands that you have rights over your personal
          information, and takes reasonable steps to allow you to access, correct,
          amend, delete, port, or limit the use of your personal information. As a
          helper or seeker, you can update many types of personal information,
          directly within your account settings. If you are unable to change your
          personal information within your account settings, or if you are
          concerned about data collected as you visit Choro’s websites,
          please contact us to make the required changes.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          Please note that if you send us a request relating to your personal
          information, we have to make sure that it is you before we can respond.
          In order to do so, we may ask to see documentation verifying your
          identity, which we will discard after verification.
        </TextBodyReg>
      </div>
    </PageMargined>
  );
};

export default PrivacyTerms;
