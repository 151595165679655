import React, { useState } from 'react';
import Option from './Option';
import Card from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const DropdownSelected = styled.div`
  border-color: #96c8da;
  border-width: 1px;
  border-radius: .29rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0.67rem;
  gap: 0.67rem;
  input {
    border: 0px !important;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`;

const DropdownOptions = styled.div`
  position: absolute;
  margin-top: -2px;
  background-color: white;
  max-height: 10rem;
  overflow-y: auto;
  border-color: #96c8da;
  border-width: 0 1px 1px 1px;
  border-radius: 0 0 .29rem .29rem;
  width: 100%;
`;

const Input = styled.input`
  width: ${props => `${Math.max(props.value.length * 0.6, 10)}em !important`};
  flex-grow: 2;
  padding: 0 !important;
  max-width: 100%;
`;

const SearchText = styled.p`
  padding: .79rem 1.14rem;
  line-height: 0.8em;
  font-size: 0.8rem;
  font-weight: 400;
  opacity: 0.6;
`;

let selectedToArray = (selected) => {
  let arrayVersion = [];
  for (let selection in selected) {
    for (let i=0; i<selected[selection].amount; i++) {
      arrayVersion.push(selection);
    }
  }
  return arrayVersion;
};

const initialSelected = (arr, options) => {
  let initialSelected = {};
  if (!arr) return initialSelected;
  let filteredOptions = options.filter(option => arr.includes(option.value));
  for (let option of filteredOptions) {
    initialSelected[option.value] = { text: option.text, amount: 0, value: option.value };
  }
  for (let selection of arr) {
    if (initialSelected[selection]) {
      initialSelected[selection].amount ++;
    }
  }
  return initialSelected;
};

export default function Dropdown({ options, onChange, value }) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(initialSelected(value, options));
  const [search, setSearch] = useState('');
  const filteredOptions = options.filter((option) => option.text.toLowerCase().includes(search.toLowerCase()));
  const highlighted = filteredOptions[0];

  const newSelection = (option) => {
    const newSelected = { ...selected };
    if (newSelected[option.value]) {
      newSelected[option.value] = { ...newSelected[option.value], amount: newSelected[option.value].amount + 1 };
    } else {
      newSelected[option.value] = { text: option.text, amount: 1, value: option.value };
    }

    onChange(selectedToArray(newSelected));
    setSelected(newSelected);
    setSearch('');
  };

  const enterSelection = (e) => {
    if (e.key === 'Enter') {
      newSelection(highlighted);
    };
  };

  const deSelect = (value) => {
    const newSelected = { ...selected };
    if (newSelected[value].amount > 1) {
      newSelected[value] = { ...newSelected[value], amount: newSelected[value].amount - 1 };
    } else {
      delete newSelected[value];
    }
    onChange(selectedToArray(newSelected));
    setSelected(newSelected);
  };

  return (
    <div className="relative" role="combobox" tabIndex="0" onBlur={() => setOpen(false)}>
      <DropdownSelected
        onClick={() => setOpen(true)}
      >
        {Object.values(selected).map(({ text, amount, value }) => <Card key={value} remove={deSelect} value={value} text={text} amount={amount} />)}

        <Input
          placeholder="Service..."
          value={search}
          onKeyDown={enterSelection}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={() => setOpen(true)}
        />
        <StyledFontAwesomeIcon
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOpen(!open);
          }}
          icon={faCaretDown}
        />
      </DropdownSelected>
      {open && 
        <DropdownOptions
          role="listbox"
        >
          {filteredOptions.length < 1 && <SearchText>No Results Found.</SearchText>}
          {filteredOptions.map(option => <Option key={option.key} option={option} onSelect={newSelection} highlighted={highlighted} />)}
        </DropdownOptions>
      }
    </div>
  );
}
