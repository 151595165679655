import React from 'react';
import PageMargined from 'components/common/Layout/PageMargined';
import { SecondaryHeading, LandingHeadingFour, TextBodyReg } from 'components/common/Textbox';

const Agreement = () => {
  return (
    <PageMargined>
      <div className="
        sm:py-6
        md:py-24
        "
      >
        <SecondaryHeading>Terms of Use</SecondaryHeading>
        <br />
        <LandingHeadingFour>Welcome to Choro!</LandingHeadingFour>
        <TextBodyReg>
          These Terms of Use (“Terms”) govern your rights and obligations regarding the use of Neighborli Services Inc.’s software (“Software”) and Choro.ca service (both collectively referred to as the “Service”) on the Internet or in cellular media. These Terms constitute a fully binding agreement between Neighborli Services Inc. (including its affiliates and subsidiaries, herein after collectively referred to as “Choro” or “We”) the proprietor of all rights in and to the Service, and you (“Agreement”). Please carefully read these Terms.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          By using the Service, you hereby agree to these Terms and all the terms herein.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          If you do not agree to these Terms or any of its parts, then you are prohibited and must refrain from using the Service.
        </TextBodyReg>
        <br /> 
        <LandingHeadingFour>WHAT IS THE SERVICE</LandingHeadingFour>
        <TextBodyReg>
          The Service is a web-based communications platform that enables connections between seekers (“Seekers”) and helpers (“Helpers”).  Seekers are individuals or organizations seeking to obtain short-term services or volunteer work from individuals who live nearby and are therefore clients of these Helpers.  Helpers are individuals living nearby the Seekers and are looking to provide companionship and help with odd chores around the home for which they may or may not receive remuneration. 
        </TextBodyReg>
        <br /> 
        <LandingHeadingFour>LICENCE</LandingHeadingFour>
        <TextBodyReg>
          If you agree to the Terms and you are of age of majority (19 years of age), and of capacity to use the Service and be bound by the Terms, we grant you a limited, revocable, non-assignable licence to use the Service in compliance with the Terms.  Unlicenced use is unauthorized.  You agree not to display, “frame,” make derivative works, distribute, licence or sell content from the Service, excluding the postings you create.  You also agree to not provide any false or misleading information in the Service.  You grant us a perpetual, irrevocable, unlimited, worldwide fully paid/sublicensable licence to use, copy, display, distribute and make derivative works from content you post. 
        </TextBodyReg>
        <br /> 
        <LandingHeadingFour>DEFINITIONS</LandingHeadingFour>
        <TextBodyReg>
          In this agreement, the term “Choro Activities” shall include all activities related to using the Choro.org website which shall include signing onto the website, providing information about yourself for public viewing, offering services, and hiring Helpers who have offered their services through the website.  Such services can include entering the homes or locations of those hiring, inviting Helpers to your home, completion of tasks including contact with other people, contact with and usage of household appliances, tools, utensils, machinery, etc. 
        </TextBodyReg>
        <br /> 
        <LandingHeadingFour>CHORO OPERATES AS AN ONLINE MARKETPLACE</LandingHeadingFour>
        <TextBodyReg>
          Choro operates as an online marketplace that connects Seekers with Helpers who wish to perform a variety of tasks. Choro does not perform tasks and does not employ people to perform tasks. Helpers operate as independent contractors and are customarily engaged in an independently established business of the same nature as that involved in the services performed for Seekers through the platform. Choro does not control or direct the Helpers performance of their services or set their work locations or work hours. Helpers provide services under their own name or business name, and not under Choro’s name. Helpers provide their own personal protective equipment (“PPE”), tools and supplies to perform their services; Choro does not provide the PPE, tools or supplies. Helpers are free to maintain a clientele without any restrictions from Choro and are free to offer and provide their services elsewhere, including through competing platforms. Choro does not set Helpers’ hours or terms of work. Helpers are free to accept or reject Seekers and contracts. Helpers are not penalized for rejecting Seekers or contracts, though if Helpers accept a Seeker or contract through the Choro platform, they are expected to fulfill their contractual obligations unless required to cancel because of illness (or Covid-19 on the part of the Seeker or Helper) in accordance with the terms set by Choro. 
          The Service is not an employment agency service or business and Choro is not an employer of any Helper or user. Should Helpers wish to be insured for workplace injuries, as independent contractors, they are required to purchase their own workers compensation insurance (“WCB”). 
        </TextBodyReg>
        <br /> 
        <LandingHeadingFour>DISCLAIMER</LandingHeadingFour>
        <TextBodyReg>To the full extent permitted by law, Choro: </TextBodyReg>
        <TextBodyReg>
          <ul className="list-disc ml-5">
            <li>
              makes no promises, warranties, or representations as to the Service,
              including its completeness, accuracy, availability, timeliness,
              propriety, security or reliability;{' '}
            </li>
            <li>
              provides the Service on an &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis and any risk of using the Service is assumed by you; {' '}
            </li>
            <li>
              disclaims all warranties, express or implied, including as to accuracy, merchantability, fitness for a particular purpose or job, and non-infringement; and
            </li>
            <li>
              disclaim any liability or responsibility for acts, omissions, or conduct of you or any party in connection with Choro. 
            </li>
          </ul>
        </TextBodyReg>
        <br />
        <TextBodyReg>
          Choro is NOT liable for any direct, indirect, consequential, incidental, special, punitive, or other losses, including lost profits, revenues, data, goodwill, etc., arising from or related to Choro or the Service.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>ACKNOWLEDGEMENT OF RISKS</LandingHeadingFour>
        <TextBodyReg>
          You are aware that participation in Choro Activities involves many risks, dangers and hazards including, but not limited to: incomplete or incorrect information; tardiness; not showing up on time or at all; failure to pay; injuries; illnesses such as Covid-19 and other illnesses, damage to persons or property, possible theft; falls; loss of balance; collisions with natural and constructed objects or other participants; encounters with rude or violent individuals; negligence of Helpers other persons; and NEGLIGENCE ON THE PART OF CHORO. YOU UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF CHORO TO TAKE REASONABLE STEPS TO SAFEGUARD OR PROTECT YOU FROM THE RISKS, DANGERS AND HAZARDS OF CHORO ACTIVITIES. YOU ARE AWARE OF THE RISKS, DANGERS AND HAZARDS ASSOCIATED WITH CHORO ACTIVITIES AND YOU FREELY ACCEPT AND FULLY ASSUME ALL SUCH RISKS, DANGERS AND HAZARDS AND THE POSSIBILITY OF PERSONAL INJURY, DEATH, PROPERTY DAMAGE OR LOSS RESULTING THEREFROM. You are further aware that the Helpers may make misrepresentations and/or may not be certified for the skills or tasks you have hired them for.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>LIMITATION OF LIABILITY, WAIVER AND INDEMNITY</LandingHeadingFour>
        <TextBodyReg>You hereby agree as follows:</TextBodyReg>
        <TextBodyReg>
          <ol className="list-decimal ml-5">
            <li>
              TO WAIVE ANY AND ALL CLAIMS that you have or may in the future have against Neighborli Services Inc., their respective directors, officers, members, employees, agents, representatives, independent contractors, successors and assigns (all of whom are hereinafter collectively referred as “the Releasees”) and TO RELEASE THE RELEASEES from any and all liability for any injury, loss, damage or expense, including death, that I may suffer or that my next of kin may suffer, as a result of my participation in using the Service, DUE TO ANY CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF ANY STATUTORY OR OTHER DUTY OF CARE, INCLUDING ANY DUTY OF CARE OWED UNDER THE OCCUPIERS LIABILITY ACT, R.S.B.C. 1996, c. 337 ON THE PART OF THE RELEASEES. I UNDERSTAND THAT NEGLIGENCE INCLUDES FAILURE ON THE PART OF THE RELEASEES TO SAFEGUARD OR PROTECT ME FROM THE RISKS, DANGERS AND HAZARDS OF PARTICIPATING IN ONLINE WEBSITE ACTIVITIES REFERRED TO ABOVE; 
            </li>
            <li>
              TO HOLD HARMLESS AND INDEMNIFY THE RELEASEES for any and all liability for any property damage, loss or personal injury to any third party resulting from my participation in Choro Activities; 
            </li>
            <li>
              This Agreement shall be effective and binding upon my heirs, next of kin, executors, administrators, assigns and representatives, in the event of my death or incapacity; 
            </li>
            <li>
              This Agreement and any rights, duties and obligations as between the parties to this Release Agreement shall be governed by and interpreted solely in accordance with the laws of the Province of British Columbia and no other jurisdiction; and 
            </li>
            <li>
              Any litigation involving the parties to this Agreement shall be brought solely within the Province of British Columbia and shall be within the exclusive jurisdiction of the Courts of the Province of British Columbia. In entering into this Agreement I am not relying on any oral or written representations or statements made by the Releasees with respect to the safety of Choro Activities, other than what is set forth in this Agreement. I CONFIRM THAT I HAVE READ AND UNDERSTOOD THIS AGREEMENT PRIOR TO SIGNING UP ON THE WEBSITE, AND I AM AWARE THAT BY SIGNING UP ON THE WEBSITE I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, NEXT OF KIN, EXECUTORS, ADMINISTRATORS, ASSIGNS AND REPRESENTATIVES MAY HAVE AGAINST THE RELEASEES.
            </li>
          </ol>
        </TextBodyReg>
        <br />
        <TextBodyReg>
          In entering into this Agreement, I am not relying on any oral or written representations or statements made by the Releasees with respect to the safety of Choro Activities, other than what is set forth in this Agreement. I CONFIRM THAT I HAVE READ AND UNDERSTOOD THESE TERMS, AND I AM AWARE THAT BY AGREEING TO THESE TERMS I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, NEXT OF KIN, EXECUTORS, ADMINISTRATORS, ASSIGNS AND REPRESENTATIVES MAY HAVE AGAINST THE RELEASEES.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>TERMINATION OF SERVICE</LandingHeadingFour>
        <TextBodyReg>
          Choro reserves the right at any time, to terminate the provision of the Service in its entirety or any part thereof, temporarily or permanently, or terminate the subscription of any user of its services at its sole discretion.  Any violation or breach of the Terms will result in termination of your rights to use this Service as well as all rights under this Agreement.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>PRIVACY</LandingHeadingFour>
        <TextBodyReg>
          Choro respects your privacy during your use of the Software and the Service. Our updated Privacy Policy pertaining to the Software and the Service is readily accessible on our website and our Privacy Policy is an integral part of these Terms. Since the Privacy Policy is subject to periodic updates, it is recommended that you periodically review the policy for updates.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>COPYRIGHT</LandingHeadingFour>
        <TextBodyReg>
          All intellectual property rights in and to the website, Software, the Service and its database, including copyrights, trademarks, industrial designs, patents and trade secrets – are either the exclusive property of Choro or its affiliates or are exclusively licensed to Choro.  This includes but is not limited to: text, graphics, editorial content, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces and other content and any suggestions, ideas, enhancement requests, feedback, recommendations, or other information provided by any user or third party relating to the Software or the Service.  The Service is protected, among others, by the Canadian Copyright Act, RSC 1985, c C-42, as well as by applicable copyright provisions prescribed by any other law, in North America and elsewhere.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          “Choro”, the Choro logo, and other trade and/or service marks are the property of Choro or its affiliates and you may not use such logos or marks for any purpose that is not expressly authorized in these Terms without the prior written consent of Choro.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          The design, trade dress, and the ‘look and feel’ of the maps of the website and the Service are protected works under applicable copyright laws and Choro and its affiliates retain all intellectual property rights in them. The Software license granted to you in these Terms does not extend to or include a license to use the graphics, artwork or images displayed on the Software or any mark, indicator, logo or notation embedded in the website that are displayed on the Software. You may not copy or print more than one copy of any data or material appearing on the website.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          Choro may protect the Service by technological means intended to prevent unauthorized use of the Service. You undertake not to circumvent these means. Without derogating from Choro’s rights under these Terms or under any applicable law, you are advised that any attempted or actual infringement of this provision will result in the termination of all your rights under these Terms. If you circumvent any of the means taken by Choro to protect the Service from unauthorized use, you must immediately cease any and all use of the Service, and you undertake to do so.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>MODIFICATIONS TO THE SERVICE AND SOFTWARE</LandingHeadingFour>
        <TextBodyReg>
          Choro may, either partially or in its entirety and without being obligated to provide prior notice – modify, adapt or change the Software, the Service’s features, the user interface and design, the extent and availability of the contents in the Service and any other aspect related to the Service. You will have no claim, complaint or demand against Choro for applying such changes or for failures, damages, loss or costs you suffer as a result of such changes.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>GOVERNING LAW AND JURISDICTION</LandingHeadingFour>
        <TextBodyReg>
          These Terms, the Software and the Service will be governed solely by the laws of British Columbia, Canada, without giving effect to any conflicts of law principles. Any dispute, claim or controversy arising out of, connected with or relating to these Terms, the Software and the Service, will be under the exclusive jurisdiction of the court of competent jurisdiction in the Province of British Columbia, Canada.
        </TextBodyReg>
        <br />
        <LandingHeadingFour>DISPUTE RESOLUTION</LandingHeadingFour>
        <TextBodyReg>
          To expedite resolution and reduce the cost of any dispute, controversy or claim related to, arising from or regarding your use of the Choro Software or Service, your relationship with Choro, or this Agreement (including previous versions) (“Dispute”), you and Choro agree to first attempt to negotiate any Dispute informally for at least 30 days before initiating any arbitration (if you are a resident of U.S or Canada).
        </TextBodyReg>
        <br />
        <TextBodyReg>
          PLEASE READ THIS SECTION CAREFULLY — IT AFFECTS YOUR LEGAL RIGHTS AND GOVERNS HOW YOU AND CHORO CAN BRING CLAIMS AGAINST EACH OTHER. THIS SECTION WILL, WITH LIMITED EXCEPTIONS, REQUIRE YOU AND CHORO TO SUBMIT CLAIMS AGAINST EACH OTHER TO BINDING AND FINAL ARBITRATION ON AN INDIVIDUAL BASIS.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          Except where prohibited by law, any controversy, claim or dispute arising out of, relating to, or in respect of these Terms, including their negotiation, validity, existence, breach, termination, construction or application, or the rights, duties or obligations of any party, or the rights, duties or obligations of any party derived from or associated with these Terms (a <b>“Dispute”</b>), shall be referred to and determined by a single arbitrator in a final and binding arbitration under the British Columbia Arbitration Act, SBC 2020, c 2, and administered under the rules of the Vancouver International Arbitration Centre (“VANIAC”). If the parties have not agreed upon an arbitrator within 14 days, unless otherwise agreed by the parties in writing, the parties shall ask the VANIAC to appoint a single arbitrator in accordance with their rules. For greater certainty, neither Helpers nor Seekers shall commence or participate in a class proceeding or other aggregate action in respect of any Dispute, except and to the extent that provincial consumer protection legislation expressly preserves such an entitlement in the context of the particular Dispute.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          The seat of the arbitration shall be the same as the provincial or territorial law governing this Agreement (i.e. the Province of British of Columbia). The arbitration shall be heard in Vancouver, British Columbia, unless the parties agree otherwise. The costs and expenses of the arbitrator shall be shared equally between the parties. A party to the arbitration has no right of appeal from any award of the Arbitrator, whether characterized as final, interim, interlocutory or partial.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          All Disputes referred to arbitration (including the scope of the agreement to arbitrate, the law relating to the enforcement of the agreement to arbitrate, any relevant limitation periods, the law governing the procedure of the arbitration, the law relating to available remedies, set-off claims and conflict of laws rules) shall be governed by the law of the seat. Each party hereby irrevocably consents to venue in Vancouver, British Columbia, and to the jurisdiction of competent courts in British Columbia for all litigation that may be brought; however it is agreed and acknowledged that the intention of the parties is to arbitrate the Dispute without recourse to the courts.
        </TextBodyReg>
        <br />
        <TextBodyReg>
          A party to this Agreement may take such steps as are permitted or required to enforce an award made by an Arbitrator. Except as required by law, and only to the extent that such disclosure is reasonably necessary, or for the purposes of obtaining professional advice, the existence of the arbitration and any element of the arbitration, including any award, shall be confidential and shall not be disclosed to any non-party to the arbitration. No document or other evidence or information prepared for or produced by or on behalf of any party to the arbitration shall be disclosed to any non-party to the arbitration.
        </TextBodyReg>
      </div>
    </PageMargined>
  );
};

export default Agreement;
