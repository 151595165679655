import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import Content from 'components/common/Content.jsx';
import appContext from 'contexts/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'semantic-ui-react';

const PaymentRegistrationConfirmation = () => {
  const { currentUser } = useContext(appContext);

  return (
    <Content padding>
      <SubTitleCard> <FontAwesomeIcon icon={faWallet}/> You have successfully registered for payments!</SubTitleCard>
      <h3>
        You can now recieve payments for any of your bookings.
      </h3>
      <br></br>
      <Link to={`/helpers/${currentUser.id}/edit`}><Button><FontAwesomeIcon icon={faArrowLeft}/> Back to Profile</Button></Link>
    </Content>
  );
};

export default PaymentRegistrationConfirmation;
