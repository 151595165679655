import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown, faFilter } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableFilters from './TableFilters';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  container: {
    maxHeight: 500
  },
  filter: {
    right: '-100px'
  }
});

export default function SimpleTable({ rows, headers, filterOptions }) {
  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const { setSort, sort, page, setPage, totalPages, filters, setFilters } = filterOptions || {};
  return (
    <Paper>
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className="relative">
              {headers.map((item,i) => (
                <TableCell className={sort && 'cursor-pointer'} key={i} align={i === 0 ? 'left': 'right'} onClick={() => setSort && setSort(item)}>
                  {item}
                &nbsp;
                  {sort && sort.item === item && (sort.direction == 'asc' ? <FontAwesomeIcon icon={faSortUp}/> : <FontAwesomeIcon icon={faSortDown}/>)}
                </TableCell>
              ))}
              {filters &&
                <TableCell className={classes.filter}>
                  <Tooltip title="Filter">
                    <IconButton aria-label="filter list" onClick={() => setShowFilters(!showFilters)}>
                      <FontAwesomeIcon icon={faFilter}/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              }
            </TableRow>
            {
              showFilters &&
                <TableFilters headers={headers} filters={filters} setFilters={setFilters}/>
            }
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                {row.map((item, i) => (
                  <TableCell colSpan={row.length == 1 ? '100%' : 1} key={i} align={i === 0 ? 'left': 'right'}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {setPage && <TablePagination
        component="div"
        count={totalPages}
        rowsPerPage={10}
        rowsPerPageOptions={[10]}
        labelDisplayedRows={() => `page ${page + 1} ${(totalPages != -1) ? `of ${totalPages + 1}` : ''}`}
        page={page}
        onChangePage={(e, pageNumber) => setPage(pageNumber)}
      />}
    </Paper>
  );
}
