import React, { useState, useEffect, useCallback, useContext } from 'react';
import { getConversations, getConversation } from 'requests/chat';
import { Grid } from 'semantic-ui-react';
import Conversations from './Conversations';
import Messages from './Messages';
import Chatbar from './Chatbar';
import ChatDetails from './ChatDetails';
import ActionCable from 'actioncable';
import { useLocation } from 'react-router-dom';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard';
import appContext from 'contexts/AppContext';

const Chat = props => {
  //state data setup
  const initialData = {
    conversations: [],
    current_convo: {}
  };
  const [data, setData] = useState(initialData);
  const [messagesChannel, setMessagesChannel] = useState(null);
  const location = useLocation();
  const { currentUser } = useContext(appContext);
  //fetch data once on page load
  useEffect(() => {
    //get list of conversations
    if (currentUser) {
      getConversations()
        .then(res => {
          setData(data => {
            return {
              ...data,
              conversations: res.data
            };
          });
        });
    } else {
      // **FIX** Instead should use protected route
      window.location.replace('/login');
    }
  }, [location, currentUser]);

  //Subscribe to conversation channel to see real time creation of new Conversations
  //Not functional yet, to be fixed in future update
  // useEffect(()=> {
  //   const handleReceivedConversation = response => {
  //     const { conversation } = response;
  //     setData(data => {
  //       return {
  //         ...data,
  //         conversations: [...data.conversations, conversation]
  //       };
  //     });
  //   };

  //   const cable = ActionCable.createConsumer("ws://localhost:3001/cable");
  //   const channel = cable.subscriptions.create(
  //     {
  //       channel: "ConversationChannel"
  //     },
  //     {
  //       received: handleReceivedConversation
  //     }
  //   );
  //   console.log(channel)
  //   return ()=>channel.unsubscribe();
  // }, [])

  const handleReceivedMessage = useCallback(response => {
    const { message } = response;

    setData(data => {
      return {
        ...data,
        current_convo: {
          ...data.current_convo,
          messages: [...data.current_convo.messages, message]
        }
      };
    });
  }, []);

  //function for subscribing specific message channel
  const subscribeMessages = id => {
    messagesChannel && messagesChannel.unsubscribe();
    const cable = ActionCable.createConsumer(process.env.REACT_APP_CABLE_PATH);
    setMessagesChannel(
      cable.subscriptions.create(
        {
          channel: 'MessagesChannel',
          conversation: id
        },
        {
          received: handleReceivedMessage
        }
      )
    );
  };

  // set current convo if redirected from start chat button at profile page
  useEffect(() => {
    if (location && location.state) {
      const { person1_id, person2_id } = location.state.conversation;
      const current_convo =
        data.conversations.find(convo => {
          return ((convo.person1_id === person1_id && convo.person2_id === person2_id) || (convo.person1_id === person2_id && convo.person2_id === person1_id));
        }) || {};

      subscribeMessages(current_convo.id);

      if (current_convo.id) {
        getConversation(current_convo.id)
          .then(res => {
            setData(data => {
              return {
                ...data,
                current_convo: res.data
              };
            });
          });
      }
    }
    // ignore dependency issues for now, to be fix in the future by refactoring with useReducer
  }, [data.conversations, location.state]);

  // on click, subscribe to the convo's messages channel and set current_convo
  // re-fetch convo from back-end to ensure the latest messages are loaded

  const openChat = id => {
    subscribeMessages(id);
    getConversation(id)
      .then(res => {
        setData(data => {
          return {
            ...data,
            current_convo: res.data
          };
        });
      });
  };

  return (
    <Content paddingBottom>
      <SubTitleCard>Chat</SubTitleCard>
      <Grid columns={3} padded>
        <Grid.Row stretched>
          <Grid.Column width="4">
            <Conversations
              current_convo_id={data.current_convo.id}
              conversations={data.conversations}
              handleClick={openChat}
              history={props.history}
            />
          </Grid.Column>

          <Grid.Column width="8">
            {data.current_convo.id ? (
              <Messages
                conversation={data.current_convo}
                history={props.history}
              />
            ) : (
              <div>Pick a conversation from the left</div>
            )}
            <Chatbar conversation_id={data.current_convo.id} />
          </Grid.Column>
          <Grid.Column width="4">
            {data.current_convo.id &&
              <ChatDetails conversation={data.current_convo}/>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Content>
  );
};

export default Chat;
