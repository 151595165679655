import React from 'react';
import Requests from 'screens/dashboard/Requests';
import SubTitleCard from 'components/common/SubTitleCard.jsx';

export default function RequestsDashboard() {
  return (
    <>
      <SubTitleCard small>Pending Service Requests</SubTitleCard>
      <Requests open bookingParams={{ status: ['pending', 'approved', 'payment-ready'], time: 'future', type: 'request' }}/>
      <SubTitleCard small>Cancelled Service Requests</SubTitleCard>
      <Requests open bookingParams={{ status: ['cancelled'], type: 'request' }}/>
    </>
  );
}
