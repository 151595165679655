import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import { checkGiftCard } from 'requests/payments';

export default function GiftCardCode({ setGiftCard }) {
  const [giftCardCode, setGiftCardCode] = useState('');
  const [error, setError] = useState('');

  const testgiftCard = () => {
    if (giftCardCode) {
      checkGiftCard(giftCardCode)
        .then(giftCard => {
          if (giftCard) {
            setGiftCard(giftCard);
          } else {
            setGiftCard(null);
            setError('Invalid Gift Card Code');
          }
        })
        .catch(() => {
          setError('Invalid Gift Card Code');
        });
    }
  };

  const handleChange = (value) => {
    setError('');
    setGiftCardCode(value);
  };

  return (
    <Form.Field>
      <label>
          Gift Card
      </label>
      {error && <p className="text-red text-sm">{error}</p>}
      <Form.Input 
        name="gift_card_code"
        value={giftCardCode}
        onChange={(e, { value }) => handleChange(value)}
        onBlur={testgiftCard}
      />
    </Form.Field>
  );
}
