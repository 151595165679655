import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Modal, Form, Message, Ref, Popup, Icon, Accordion } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import locationAutocomplete from 'services/locationAutocomplete';
import { DateTimePicker } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import { getServices } from 'requests/services';
import { createBooking } from 'requests/bookings';
import { estimate } from 'requests/payments';
import ServiceSelector from 'components/common/ServiceSelector';
import Button from 'components/common/Button/ButtonPrimarySquare';
import UsersSelect from 'components/Admin/UsersSelect';
import Payments from 'components/common/Payments/Payments';
import HeadingThree from 'components/common/Textbox/HeadingThree';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import HeadingFive from 'components/common/Textbox/HeadingFive';
import { getCoupons } from 'requests/discounts';
import WeeklyDate from 'components/common/Calendar/WeeklyDate';
import GiftCardCode from 'components/Payments/GiftCardCode';
import HelperMap from 'components/Map/HelperMap';

const ModalContainer = styled.div`
  padding: 3rem 4rem 4rem 4rem;
  max-height: 80vh;
  background-color: white;
  color: black;
  overflow-y: scroll;
  .field {
    margin: 0 !important;
  }
  label {
    font-weight: normal !important;
    font-size: .92857143em !important;
  }
`;

const PopupStyle = {
  height: '80%',
  position: 'absolute',
  overflow: 'visible',
  top: '50%',
  left: '50%',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)'
};

Moment.locale('en');
momentLocalizer();

/**
 * Direct copy of BookingPopup with new button UI to avoid unintended changes to different app UI 
 */
function NewRequest({ booking, small }) {
  const { currentUser } = useContext(appContext);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [seeker, setSeeker] = useState({});
  const [helper, setHelper] = useState({});
  const [isRequest, setIsRequest] = useState(false);
  const [addPayment, setAddPayment] = useState(false);
  const [helpOptions, setHelpOptions] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const history = useHistory();
  const location = useLocation();
  const locationSearchRef = useRef();
  const [submitted, setSubmitted] = useState(false);

  const [errors, setErrors] = useState({
    name_of_client: null,
    address: null,
    phone_number: null,
    date: null,
    email: null,
    help_needed: null,
    additional_info: null
  });
  
  const measuredRef = useCallback(node => {
    if (node !== null) {
      const input = node.querySelector('input');
      locationAutocomplete(input);
      locationSearchRef.current = input;
    }
  }, []);

  useEffect(() => {
    setData({
      ...data,
      name_of_client: (booking && booking.name_of_client),
      address: (booking && booking.address),
      phone_number: booking && booking.phone_number,
      email: (booking && booking.email),
      emergency_contact: {
        name: booking && booking.emergency_contact && booking.emergency_contact.name,
        relation: booking && booking.emergency_contact && booking.emergency_contact.relation, 
        phone_number: booking && booking.emergency_contact && booking.emergency_contact.phone_number
      },
      additional_info: booking && booking.additional_info,
      status:'pending',
      help_for: 'themselves',
      cost: 25.99
    });
    if (booking) {
      changeHelpOptions(booking && booking.services ? booking.services.split(/,\s*/) : []);
      setSeeker({ id: booking.seeker_user_id });
      setHelper({ id: booking.user_id });
    }
  }, [booking]);

  useEffect(() => {
    getCoupons()
      .then(coupons => {
        coupons = coupons.map(item => {
          return { key: item.id, text: item.name, value: item };
        });
        setCoupons(coupons);
      });
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: item.name };
        });
        setHelpOptions(services);
      });
  }, []);

  const updateSeeker = (user) => {
    setSeeker(user);
    setData(data => ({
      ...data,   
      name_of_client: user.email,
      address: user.address,
      phone_number: user.phone_number,
      email: user.email })
    );
    setErrors(errors => {
      let newErrors = { ...errors };
      delete newErrors['seeker'];
      return newErrors;
    });
  };

  const updateHelper = (user) => {
    setHelper(user);
    setErrors(errors => {
      let newErrors = { ...errors };
      delete newErrors['helper'];
      return newErrors;
    });
  };

  const checkError = (update = true) => {
    let newErrors = { ...errors };
    let error = false;
    for (const label in errors) {
      if ((!data[label] || (Array.isArray(data[label]) && data[label].length === 0)) && Object.keys(errors).includes(label)) {
        newErrors[label] =  'Cannot be left blank';
        error = true;
      } else {
        newErrors[label] = null;
      }
    }
    if (!seeker.id) {
      newErrors['seeker'] =  'You must choose a client!';
      error = true;
    }
    if (isRequest ? helper.length < 1 : !helper.id) {
      newErrors['helper'] =  'You must choose a helper!';
      error = true;
    }
    if (addPayment && !data.stripe_payment_method) {
      newErrors['payment'] =  'You must enter a payment!';
      error = true;
    }
    update && setErrors(newErrors);
    return error;
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { help_needed } = data;
    if (checkError()) return;
    const services = help_needed.join(', ');
    setSubmitted(true);
    createBooking({ 
      ...data,
      services, 
      seeker_user_id: seeker.id,
      user_id: isRequest ? null : helper.id,
      stripe_coupon_id: data.discount && data.discount.id,
      gift_card_id: data.gift_card && data.gift_card.id,
      requested_helpers: isRequest ? helper.map(helper => helper.id) : null
    })
      .then((booking) => {
        setOpen(false);
        history.push({
          pathname: `/bookings/${booking.id}`
        });
      })
      .catch(error => {
        console.log(error);
        setSubmitted(false);
      });
  };

  const handleChange = (e, type) => {
    let { name, value } =  (e && e.target) || {};
    if (!name && !value) {
      name = type.name;
      value = type.value;
    }
    if (type === 'emergency') {
      setData({ ...data, emergency_contact: { ...data.emergency_contact, [name]: value } });
    } else {
      setData({ ...data, [name]: value });
    }
    errors[name] && setErrors({ ...errors, [name]: null });
  };

  const handleAddressChange = () => {
    setTimeout(() => {
      setErrors({ ...errors, address: null });
      setData(data => {
        return { ...data, address: locationSearchRef.current.value };
      });
    }, 10);
  };

  const handleCheckboxChange = (e, boxClicked) => {
    setErrors({ ...errors, help_for: null });
    if (boxClicked === 'someone else') {
      setData({ ...data,
        help_for: boxClicked,
        name_of_client: null,
        address: null
      });
    } else if (seeker) {
      setData({ ...data, 
        name_of_client: (booking && booking.name_of_client) || `${seeker.first_name} ${seeker.last_name}`, 
        address: (booking && booking.address) || seeker.address,
        help_for: boxClicked,
        email: seeker.email
      });
    }
  };

  const changeHelpOptions = (value) => {
    estimate(value)
      .then(cost => {
        setData(data => ({ ...data, help_needed: value, cost }));
      });
  };

  const handleDateChange = (date) => {
    if (Moment(date).isBefore(Moment())) {
      setData({ ...data, date: undefined });
      setErrors({ ...errors, date: 'You cannot select dates in the past' });
    } else {
      setData({ ...data, date });
      setErrors({ ...errors, date: null });
    }
  };

  const toggleServiceRequest = () => {
    if (isRequest) {
      setErrors((current) => {
        let newErrors = { ...current };
        delete newErrors.requested_time_range;
        newErrors.date = null;
        return newErrors;
      });
    } else {
      setErrors((current) => {
        let newErrors = { ...current };
        delete newErrors.date;
        newErrors.requested_time_range = null;
        return newErrors;
      });
    }
    setIsRequest(!isRequest);
  };

  const openModal = () => {
    if (!currentUser) {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to book help.',
          from: location.pathname
        }
      });
    } else {
      setOpen(true);
    }
  };

  const servicesBreakdown = () => {
    if (data.help_needed) {
      const servicesObj = {};
      for (let service of data.help_needed) {
        if (!servicesObj[service]) {
          servicesObj[service] = 1;
        } else {
          servicesObj[service] += 1;
        }
      }
      return data.help_needed.length > 0 ? Object.entries(servicesObj).map(([service, amount], index) => (
        <p key={index} className="flex justify-between"><span>{service} :</span> <strong>{amount/2} hour{amount > 2 && 's'} </strong></p>
      )) : <p>Minimum Charge</p>;
    } else {
      return <p>Minimum Charge</p>;
    }
  };

  const discountAmount = () => {
    return data.discount ? Math.round(100*(data.cost * data.discount.percent_off/100))/100 : 0;
  };

  const totalCost = (withGiftCard = false) => {
    return Math.max(Math.round((data.cost - discountAmount())*100 - (withGiftCard && data.gift_card ? data.gift_card.remaining_balance : 0))/100, 0);
  };

  return (
    <Modal
      basic
      style={PopupStyle}
      onClose={() => setOpen(false)}
      onOpen={openModal}
      open={open}
      closeIcon
      trigger={  (
        <Button small={small}>
          {booking ? 'Rebook' : 'New Request'}
        </Button>
      )}
    >
      <ModalContainer>
        <a href="#" onClick={() => setOpen(false)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
        <div className="flex flex-col items-center text-center mb-8">
          <HeadingThree>{booking ? `Rebook ${booking.first_name_of_seeker} and ${booking.first_name_of_helper}` : 'Create A Booking'}</HeadingThree>
          <p className="max-w-sm my-4">
            Please fill in the following information to ensure we provide the best help possible <br></br>
          </p>
        </div>
        <Form className="flex flex-col gap-8">
          <HeadingFive>Client Information</HeadingFive>
          {!booking &&
            <Form.Field required error={errors.seeker}>
              <label>Client</label>
              <UsersSelect filters={{ type: 'seeker', page: 'all' }} passSelected={updateSeeker}/>
            </Form.Field> 
          }
          <div className="flex flex-col gap-3">
            <Form.Field required error={errors.checkboxError}>
              <label>Is the help for themselves or someone else?</label>
            </Form.Field>
            <Form.Checkbox
              radio
              label='Themselves'
              name='checkboxRadioGroup'
              value='themselves'
              checked={data.help_for === 'themselves'}
              error={errors.help_for && {
                content: errors.help_for,
                pointing: 'left'
              }}
              onChange={(e) => {handleCheckboxChange(e, 'themselves');}}
            />
            <Form.Checkbox
              radio
              label='For someone else'
              name='checkboxRadioGroup'
              value='someone else'
              checked={data.help_for === 'someone else'}
              onChange={(e) => {handleCheckboxChange(e, 'someone else');}}
            />
          </div>
          {(data.help_for === 'someone else') && 
            <>
              <Form.Field required className="flex flex-col gap-3">
                <label>
                  Client Information
                </label>
                <Form.Input
                  name="name_of_client"
                  placeholder="Name of client"
                  value={data.name}
                  error={errors.name_of_client}
                  onChange={(e) => {handleChange(e);}}
                  required
                />
                <Ref innerRef={measuredRef}>
                  <Form.Input
                    name="address"
                    placeholder="Address of client"
                    onChange={handleAddressChange}
                    onBlur={handleAddressChange}
                    error={errors.address}
                    required
                  />
                </Ref>
                <Form.Input
                  name="phone_number"
                  placeholder="Phone number of client"
                  value={data.phone_number}
                  error={errors.phone_number}
                  onChange={(e) => {handleChange(e);}}
                  required
                />
              </Form.Field>
            </>
          }
          {(data.help_for === 'themselves') && 
            <Form.Field 
              required
            >
              <label>
                Phone number
              </label>
              <Form.Input
                name="phone_number"
                placeholder="Phone number"
                value={data.phone_number}
                onChange={(e) => {handleChange(e);}}
                required
                error={errors.phone_number}
              />
            </Form.Field>
          }
          <Form.Field className="flex flex-col gap-3">
            <label>Emergency Contact Info</label>
            <Form.Input
              name="name"
              placeholder="Name of emergency contact"
              value={data.emergency_contact && data.emergency_contact.name}
              onChange={(e) => {handleChange(e, 'emergency');}}
            />
            <Form.Input
              name="phone_number"
              placeholder="Phone number of emergency contact"
              value={data.emergency_contact && data.emergency_contact.phone_number}
              onChange={(e) => {handleChange(e, 'emergency');}}
            />
            <Form.Input
              name="relation"
              placeholder="Relation to emergency contact"
              value={data.emergency_contact && data.emergency_contact.relation}
              onChange={(e) => {handleChange(e, 'emergency');}}
            />
          </Form.Field>
          <HeadingFive>Booking Information</HeadingFive>
          {!booking &&
              <Form.Field>
                <label>
                  Service Request
                  <br></br>
                  <small>Send request to multiple helpers</small>
                </label>
                <Form.Checkbox 
                  toggle
                  name="auto_accept"
                  onChange={toggleServiceRequest} 
                />
              </Form.Field>
          }
          <Form.Field required error={errors.date}>
            <label>When is the client available?</label>
            {isRequest ? 
              <WeeklyDate onChange={(value) => {handleChange(null, { name: 'requested_time_range', value });}}/>
              :
              <DateTimePicker
                onChange={handleDateChange}
                value={data.date}
                name="date"
                error={errors.date}
                required
              />
            }
            {((!isRequest && errors.date) || (isRequest && errors.requested_time_range)) && 
                <Message 
                  negative 
                  size="tiny"
                  style={{ padding: '7px', display: 'inline-block' }}
                >
                  <strong>{errors.date || errors.requested_time_range}</strong>
                </Message>
            }
          </Form.Field>
          { !booking && 
            <Form.Field error={errors.helper}>
              <Accordion defaultActiveIndex={0} panels={[{
                key: 'Helper Map',
                title: 'Helper Map',
                content: { content: <HelperMap user={seeker}/> }
              }]} />
            </Form.Field>
          }
          { !booking && 
            <Form.Field required error={errors.helper}>
              <label>Helper {isRequest && '(s)'}</label>
              <UsersSelect availability={data.requested_time_range} multiple={isRequest} filters={{ type: 'helper', charges_enabled: true, page: 'all' }} passSelected={updateHelper}/>
            </Form.Field>
          }
          {isRequest || 
            <Form.Field>
              <label>
                Auto Accept
                <br></br>
                <small>This will accept the booking on behalf of the helper</small>
              </label>
              <Form.Checkbox 
                toggle
                name="auto_accept"
                onChange={() => setData({ ...data, auto_accept: !data.auto_accept })} 
              />
            </Form.Field>
          }
          <Form.Field   required>
            <label>Select the type(s) of help {seeker && seeker.first_name ? `${seeker.first_name} needs` : 'needed'}</label>
            <ServiceSelector
              value={data.help_needed}
              error={errors.help_needed}
              name="help_needed"
              onChange={changeHelpOptions} 
              options={helpOptions} 
            />
          </Form.Field>
          <Form.Field required>
            <label>
              { data.help_needed && data.help_needed.length !== 0 ? `Additional information about ${data.help_needed.join(', ')}` : 'Additional Information'}
              <Popup
                trigger={<Icon color="grey" name="question circle outline" />}
                size={'mini'}
                // position={'center'}
                wide={'very'}
                flowing
              >
                <p><strong>Provide the following information for:</strong></p>
                <p><strong>Light Cleaning</strong></p>
                <ul className="list-disc ml-5">
                  <li>
                    Size of home
                  </li>
                  <li>
                    Confirm all cleaning supplies, equipment and cleaning solutions are on site
                  </li>
                  <li>
                    Exactly what needs cleaning: vaccuum livingroom, 1 bathroom, mopping kitchen, etc.
                  </li>
                  <li>
                    NOTE: &apos;light housekeeping&apos; and senior home help only. &nbsp;Helpers are not professional cleaners &amp; do not do extensive cleaning.&nbsp;
                  </li>
                </ul>
                <p><strong>Driving</strong></p>
                <ul className="list-disc ml-5">
                  <li>
                    Pickup Drop Off locations
                  </li>
                  <li>
                    Client needs to pay mileage of 55 cents per km and parking (if any)
                  </li>
                </ul>
                <p><strong>Errands</strong></p>
                <ul className="list-disc ml-5">
                  <li>
                    Location and exact items&nbsp;
                  </li>
                  <li>
                    Ensure <strong>Pre-Paid</strong>, to save money and avoid extra 5% platform charge
                  </li>
                  <li>
                    Client needs to pay 55 cents per km plus parking
                  </li>
                </ul>
                <p><strong>Gardening</strong></p>
                <ul className="list-disc ml-5">
                  <li>
                    Exactly what tasks are required
                  </li>
                  <li>
                    All garden tools, supplies, plantings are on site
                  </li>
                  <li>
                    Small tasks only. &nbsp;Helpers are not professional gardeners or landscapers
                  </li>
                </ul>
                <p><strong>Tech Help</strong></p>
                <ul className="list-disc ml-5">
                  <li>
                    Confirm brand and make of computer, mobile phone, tablet, TV or remote.&nbsp;
                  </li>
                  <li>
                    Confirm exactly what issues or help is needed
                  </li>
                </ul>
              </Popup>
            </label>
            <small> Extra task details and any health concerns . . .</small>
            <Form.TextArea
              name="additional_info"
              placeholder="Additional information..."
              value={data.additional_info}
              error={errors.additional_info}
              onChange={(e) => {handleChange(e, 'additional_info');}}/>
          </Form.Field>
          <Form.Field>
            <Form.Checkbox 
              name="supplies"
              label="Client confirm that I have all supplies needed to complete the task(s)"
              required
              checked={data.supplies}
              onClick={() => handleChange({}, { value: !data.supplies, name:'supplies' })}
            />
          </Form.Field>
          <hr/>
          <HeadingFive>Estimated Total</HeadingFive>
          <div className="flex justify-between">
            <div className="w-1/3 flex flex-col gap-2">
              {servicesBreakdown()}
              {
                data.discount && <p className="text-btn-primary">{data.discount.name} : <strong>{data.discount.percent_off}% off</strong></p>
              }
              <hr/>
              {
                data.gift_card ?
                  <>
                    <p className="self-end"><s>${totalCost()}</s></p>
                    <p className="text-btn-primary flex justify-between"><span>Gift Card :</span> <strong> - ${Math.min(data.cost * 100, data.gift_card.remaining_balance)/100}</strong></p>
                    <hr/>
                    <p className="self-end">${totalCost(true)}</p>
                  </>
                  :
                  <p className="self-end">${totalCost()}</p>
              }         
            </div>
            <Message className="visible mt-0 w-1/2" info size="tiny">
              {(seeker && seeker.batch_invoices) ?
                `${seeker.first_name || 'The client'} is signed up for monthly invoices and will be billed at the end of the billing cycle.
                A payment method is not required at this time as it has already been set up.`
                :
                (addPayment ?
                  `${seeker.first_name || 'The client'} will be charged when the service is complete.`
                  :
                  `${seeker.first_name || 'The client'} will be prompted for payment details once ${helper.first_name || 'the helper'} confirms the booking,
                  and charged when the service is complete.`
                )
              }
            </Message>
          </div>
          <Form.Field>
            <label>
              Skip Payment
              <br></br>
              <small>The app will not process payment for this booking</small>
            </label>
            <Form.Checkbox 
              toggle
              name="skip_payment"
              onChange={() => setData({ ...data, skip_payment: !data.skip_payment })} 
            />
          </Form.Field>
          {seeker && seeker.batch_invoices && coupons.length > 0 &&
            <Form.Field>
              <label>
                Add Discount
              </label>
              <Form.Dropdown 
                clearable 
                options={coupons} 
                selection
                name="discount"
                onChange={(e, { value }) => {handleChange(null, { name: 'discount', value });}}
              />
            </Form.Field>
          }
          {!data.skip_payment && (seeker && !seeker.batch_invoices) &&
            <>
              <Form.Field>
                <label>
                    Add Payment Method
                </label>
                <Form.Checkbox 
                  toggle
                  name="add_payment"
                  onChange={()=> setAddPayment(!addPayment)} 
                />
              </Form.Field>
              {addPayment &&
                <>
                  {coupons.length > 0 &&
                    <Form.Field>
                      <label>
                        Add Discount
                      </label>
                      <Form.Dropdown 
                        clearable 
                        options={coupons} 
                        selection
                        name="discount"
                        onChange={(e, { value }) => {handleChange(null, { name: 'discount', value });}}
                      />
                    </Form.Field>
                  }
                  <GiftCardCode setGiftCard={(giftCard) => handleChange({}, { value: giftCard, name:'gift_card' })} />
                  {totalCost(true) > 0 &&
                    <Payments user={seeker} updatePaymentMethod={pm => handleChange(null, { name: 'stripe_payment_method', value: pm })}/>
                  }
                </>
              }
            </>
          }
          <div className="flex justify-center mt-4">
            <Popup
              trigger={ 
                <span className="mr-2">
                  <Button 
                    onClick={handleSubmit}
                    disabled={submitted || checkError(false)}
                    type="submit"
                  >
                      Submit
                  </Button>
                </span>}
              disabled={!checkError(false)}
              size={'mini'}
              wide={'very'}
              position='top center'
            >
                Please fill out All required fields
            </Popup>
          </div>
        </Form>
      </ModalContainer>
    </Modal>
  );
}

export default NewRequest;
