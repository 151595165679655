import React from 'react';
import TitleCard from '../../components/common/TitleCard.jsx';
import Content from '../../components/common/Content.jsx';

const NotAdmin = () => {
  return (
    <Content>
      <TitleCard>You are not an admin</TitleCard>
      <h3>You do not have access to this resource</h3>
    </Content>
  );
};

export default NotAdmin;
