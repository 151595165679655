import { useState, useEffect, useCallback } from 'react';
import useWindowDimensions from './useWindowDimensions';

/**
 * Reusable Hook to calculate the minimum content height which makes a webpage
 * add up to 100vh
 *  
 * @returns array of minimized-text state and state-modifying functions
 */
const useMinimumContentHeight = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const windowDimensions = useWindowDimensions();
	
  // Fixed header is always set to 85px
  const fixedHeaderHeight = 85;

  const headerRef = useCallback((node) => {
    if (node) {
      setHeaderHeight(node.getBoundingClientRect().height + fixedHeaderHeight);
    }
  });

  const footerRef = useCallback((node) => {
    if (node) {
      setFooterHeight(node.getBoundingClientRect().height);
    }
  });

  // Generate a new content height on resize
  useEffect(() => {
    setContentHeight(windowDimensions.height - headerHeight - footerHeight);
  }, [windowDimensions, headerHeight, footerHeight]);

  return [contentHeight, headerRef, footerRef];
};

export default useMinimumContentHeight;
