import React from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import useAdminLocation from 'hooks/useAdminLocation';
import AppContext from 'contexts/AppContext';

const AppProvider = ({ children }) => {
  const [currentUser, setCurrentUser, updateImpersonation, impersonating] = useCurrentUser();
  const [adminLocation, setAdminLocation] = useAdminLocation();

  return (
    <AppContext.Provider value={{ setCurrentUser, currentUser, updateImpersonation, impersonating, adminLocation, setAdminLocation }} >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
