import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import NotFound from './notFound.jsx';

const routes = [
  <Route key="not-found" path="/not-found"><NotFound/></Route>,
  <Redirect key="not-found-redirect" to="/not-found" />
];

export default routes;
