import React from 'react';
import { Route } from 'react-router-dom';
import ApplicationSubmitted from './applicationSubmitted';
import UserRoute from 'components/Routes/UserRoute';
import HelperApplicationSummary from 'screens/helperApplication/HelperApplicationSummary';

const routes = [
  <Route key="applicationSubmitted" path="/submitted"><ApplicationSubmitted/></Route>,
  <UserRoute key="helper_application" path="/admin/users/:user_id/helper_application/:helper_application_id"><HelperApplicationSummary/></UserRoute>
];

export default routes;
