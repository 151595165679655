import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { getServices } from 'requests/services';
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Circle,
  Popup
} from 'react-leaflet';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import ListGroup from './common/listGroup';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';
import appContext from 'contexts/AppContext';
import { searchSeekerProfiles, getSeekerProfiles } from 'requests/seekerProfiles';

const Seekers = () => {
  const { currentUser } = useContext(appContext);
  //state data setup
  const initialData = {
    seekers: [],
    filtered_seekers: [],
    center: [49.28, -123.12],
    services: [],
    address: '',
    seeking_services: [],
    radius: 3
  };
  const [data, setData] = useState(initialData);
  const { lat, lng } = useFetchLatLng(data.address);

  //fetch data once on page load
  useEffect(() => {
    //get list of services available
    getServices()
      .then(services => {
        services = services.map(item => {
          return { key: item.id, text: item.name, value: Number(item.id) };
        });
        setData(data => {
          return {
            ...data,
            services
          };
        });
      });
    //get list of seekers
    getSeekerProfiles()
      .then(seekers => {
        seekers = seekers.map(profile => {
          profile.user.availability = profile.availability;
          profile.user.services = profile.services;
          return profile.user;
        });
        setData(data => {
          return {
            ...data,
            seekers,
            filtered_seekers: seekers
          };
        });
      });
  }, []);


  //set default map center to user location
  useEffect(() => {
    currentUser &&
      setData(data => {
        return {
          ...data,
          address: currentUser.address,
          center: [currentUser.lat, currentUser.lng]
        };
      });
  }, [currentUser]);

  //sends search request to backend whenever lat/lng/radius/services updates
  useEffect(() => {
    //only send search request if there's an address or selected services
    if (lat || data.seeking_services.length) {
      searchSeekerProfiles({
        lat,
        lng,
        radius: data.radius,
        seeking_services: data.seeking_services
      })
        .then(({ users, services }) => {
          let seekers = users.map((user, index) => {
            user.services = services[index];
            user.availability = user.seeker_profile.availability;
            return user;
          });
          setData(data => {
            return {
              ...data,
              filtered_seekers: seekers
            };
          });
        });
    }
  }, [lat, lng, data.radius, data.seeking_services]);

  //reset list if no address or offered_services
  useEffect(() => {
    if (!lat && !data.seeking_services.length) {
      setData(data => {
        return {
          ...data,
          filtered_seekers: data.seekers
        };
      });
    }
  }, [lat, data.seeking_services.length]);
  //callback function for resetting center - to be called when clicking on user cards and markers
  const reposition = (lat, lng) => {
    setData(data => {
      return {
        ...data,
        center: [lat, lng]
      };
    });
  };
  //callback function for clicking on map marker
  const clickMarker = event => {
    const { lat, lng } = event.latlng;
    reposition(lat, lng);
  };

  //callback function for form changes
  const handleChange = (e, { name, value }) => {
    setData(data => {
      return { ...data, [name]: value };
    });
  };
  //search radius circle
  const searchRadius = (
    <Circle
      key="searchRadius"
      center={
        (lat && [lat, lng]) ||
        (currentUser && [currentUser.lat, currentUser.lng])
      }
      radius={data.radius * 1000}
      fillOpacity={0.1}
    />
  );

  //making markers for all seekers
  const circles = data.filtered_seekers.map(seeker => (
    <Circle
      onClick={clickMarker}
      key={seeker.id}
      center={[seeker.lat, seeker.lng]}
      radius={100}
      fillOpacity={0.6}
      color="purple"
      stroke={false}
    >
      <Popup>
        <Link to={`./seekers/${seeker.id}`}>
          <h5 className="card-title">{seeker.first_name}</h5>
        </Link>
        <img
          src={seeker.image}
          className="card-img img-fluid height:auto thumbnail"
          alt="..."
        />
        {seeker.services &&
          seeker.services.map(service => (
            <span key={service.id} className="m-1 p-1 badge badge-success">
              {service.name}
            </span>
          ))}
      </Popup>
    </Circle>
  ));

  return (
    <>
      <h2>Here is a list of people near you who need help</h2>
      <Form className="mb-3">
        <Form.Group widths="equal">
          <Form.Input
            action={
              <Dropdown text={`${data.radius} KM`} button basic floating>
                <Dropdown.Menu>
                  <Input
                    name="radius"
                    className="search-radius"
                    onChange={handleChange}
                    type="range"
                    min={1}
                    max={10}
                    step={1}
                    defaultValue={3}
                  />
                </Dropdown.Menu>
              </Dropdown>
            }
            name="address"
            placeholder="Enter your location to search within"
            value={data.address}
            onChange={handleChange}
            icon="search"
            iconPosition="left"
          />
          <Form.Field>
            <Dropdown
              name="seeking_services"
              placeholder="Filter by type of help"
              fluid
              multiple
              search
              selection
              value={data.seeking_services}
              onChange={handleChange}
              options={data.services}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <div className="row no-gutters">
        <div className="card mb-3 col-5">
          <ListGroup
            items={data.filtered_seekers}
            handleClick={reposition}
            type="seekers"
          />
        </div>
        <div className="col-7">
          <LeafletMap
            center={data.center}
            zoom={14}
            maxZoom={20}
            attributionControl={true}
            zoomControl={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            dragging={true}
            animate={true}
            easeLinearity={0.35}
          >
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            {currentUser && (
              <Marker
                onClick={clickMarker}
                position={[currentUser.lat, currentUser.lng]}
              >
                <Popup>
                  <h5>
                    <span role="img" aria-label="home">
                      🏠
                    </span>
                    Your address is:
                  </h5>
                  {currentUser.address}
                </Popup>
              </Marker>
            )}
            {lat && (
              <Marker onClick={clickMarker} position={[lat, lng]}>
                <Popup>
                  <h5>The address you are searching for is:</h5>
                  {data.address}
                </Popup>
              </Marker>
            )}
            {searchRadius}
            {circles}
          </LeafletMap>
        </div>
      </div>
    </>
  );
};

export default Seekers;
