import React, { useState } from 'react';
import { Button, Form, TextArea } from 'semantic-ui-react';
import { sendMessage } from 'requests/chat';

const Chatbar = ({ conversation_id }) => {
  const [newMessage, setNewMessage] = useState('');
  const [rows, setRows] = useState(1);

  const handleChange = (e, { value }) => {
    setNewMessage(value);
    //adjust textarea rows
    const previousRows = e.target.rows;
    e.target.rows = 1;
    //min number of rows = 1 max = 4, default scrollHeight is 39 with each new line adds 17
    const currentRows = 1 + Math.floor((e.target.scrollHeight - 39) / 17);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows >= 4) {
      e.target.rows = 4;
      e.target.scrollTop = e.target.scrollHeight;
    }
    setRows(currentRows < 4 ? currentRows : 4);
  };

  const handleSubmit = () => {
    sendMessage(newMessage, conversation_id);
    setNewMessage('');
  };

  //Enable submit on enter
  const handleEnter = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      handleSubmit();
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className='chatbar' inline widths='equal'>
        <TextArea
          className='chatbar-textarea'
          rows={rows}
          value={newMessage}
          onChange={handleChange}
          onKeyDown={handleEnter}
          placeholder='Type a message'
        />
        <Button content='send' type='submit' primary />
      </Form.Group>
    </Form>
  );
};

export default Chatbar;
