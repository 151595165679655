import { useState, useEffect, useCallback } from 'react';
import { setDefaultHeaders, removeImpersonationHeaders } from 'helpers/headerHelpers';
import { getMe } from 'requests/users';
import { useHistory } from 'react-router-dom';

const clearImpersonationStorage = () => {
  localStorage.removeItem('impersonated-access-token');
  localStorage.removeItem('impersonated-client');
  localStorage.removeItem('impersonated-uid');
  localStorage.removeItem('impersonated-user');
};

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')));
  const [impersonatedUser, setImpersonatedUser] = useState(localStorage.getItem('impersonated-user') && JSON.parse(localStorage.getItem('impersonated-user')));
  const [fresh, setFresh] = useState(false);
  const history = useHistory();
  setDefaultHeaders();

  const updateImpersonation = useCallback((user) => {
    if (!user) {
      removeImpersonationHeaders();
      clearImpersonationStorage();
    } else {
      localStorage.setItem('impersonated-user', JSON.stringify(user));
    }
    setImpersonatedUser(user);
  }, []);

  useEffect(() => {
    if (currentUser && !fresh) {
      getMe()
        .then(user => {
          if (localStorage.getItem('impersonated-access-token')) {
            setImpersonatedUser(user);
          } else {
            setCurrentUser(user);
          }
          setFresh(true);
        })
        .catch(err => {
          let res = err.response;
          if (localStorage.getItem('impersonated-access-token')) {
            updateImpersonation(null);
          } else if (parseInt(res.status) === 401) {
            setCurrentUser(null);
            localStorage.clear();
            history.push('/login');
          }
        });
    }
  }, [currentUser, fresh, history, updateImpersonation]);

  let user = impersonatedUser || currentUser;
  let impersonating = impersonatedUser ? true : false;
  
  return [user, setCurrentUser, updateImpersonation, impersonating];
};

export default useCurrentUser;
