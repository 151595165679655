import React from 'react'; 

const HeadingSix = ({ children }) => {
  return (
    <h6 className="
      font-medium
      text-base
      leading-5
      my-0
      "
    >
      {children}
    </h6>
  );
};

export default HeadingSix;
