import { useState, useEffect, useContext } from 'react';
import appContext from 'contexts/AppContext';

const filterToParams = (filters) => {
  let params = {};
  for (let filterName in filters) {
    params[filterName] = Object.entries(filters[filterName]).reduce((acc, [optionName, option]) => {
      if (option.value) {
        return [...acc, optionName];
      }
      return acc;
    }, []);
  }
  return params;
};

const useFilters = (filterDefaults, sortDefaults, paramDefaults, sortLookup, getItems) => {
  const { adminLocation } = useContext(appContext);
  const [sort, setSort] = useState(sortDefaults || { item: null, direction: null });
  const [filters, setFilters] = useState(filterDefaults);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(-1);
  const [items, setItems] = useState({});

  useEffect(() => {
    refreshBookings();
  }, [sort, filters, page, adminLocation]);

  const refreshBookings = () => {
    getItems(paramsWithLocation())
      .then((items) => {
        if (items.length < 10) {
          setTotalPages(page);
        }
        setItems((current) => ({ ...current, [page]: items }));
      });
  };

  const paramsWithLocation = () => {
    let params = { 
      ...paramDefaults, 
      page: page, 
      order_by: sortLookup[sort.item], 
      order_direction: sort.direction,
      ...filterToParams(filters)
    };

    if (adminLocation) {
      params.location = adminLocation;
    } 

    return params;
  };

  const handleSetSort = (item) => {
    setSort({ item, direction: sort.direction == 'asc' ?  'desc' : 'asc' });
  };
  
  return { 
    items: items[page],
    filterOptions: {
      totalPages,
      page,
      setPage,
      setFilters,
      filters,
      setSort: handleSetSort,
      sort
    }
  };
};

export default useFilters;
