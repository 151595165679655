import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import appContext from 'contexts/AppContext';

const UserRoute = ({ children, path, /* goBack, */ ...rest }) => {
  const { currentUser } = useContext(appContext);
  const location = useLocation();
  return (
    <Route path={path} {...rest}>
      {currentUser ?(
        children
      ) : (
        <Redirect to={{
          pathname:'/login',
          state: {
            message: 'You must be logged in to view the other user\'s profiles',
            from: location.pathname || path
          }
        }}/>
      )}
    </Route>
  );
};

export default UserRoute;
