import React from 'react';
import { Route } from 'react-router-dom';
import LoginForm from 'components/forms/LoginForm';
import ResetPassword from 'screens/authentication/ResetPassword';
import ForgotPasswordForm from 'components/forms/ForgotPasswordForm';

const routes = [
  <Route key="login" path="/login" ><LoginForm/></Route>,
  <Route key="login" path="/" exact ><LoginForm/></Route>,
  <Route key="forgot_password" path="/forgot_password"><ForgotPasswordForm/></Route>,
  <Route key="reset" path="/auth"><ResetPassword/></Route>
];

export default routes;
