import axios from 'axios';
import { removeHeaders, setDefaultHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const uploadImage = (file) => {
  return axios.get('/presignedURL')
    .then(res => {
      removeHeaders();
      return uploadFileToS3(res.data, file);
    })
    .then(res => {
      setDefaultHeaders();
      return res;
    })
    .catch(res => handleRequestError(res, 'uploadImage'));
};

const uploadFileToS3 = (presigned, file) => {
  var bodyFormData = new FormData();
  for (const data in presigned.url_fields) {
    bodyFormData.set(data, presigned.url_fields[data]);
  }
  bodyFormData.append('file', file);
  return axios({
    method: 'post',
    url: presigned.url,
    data: bodyFormData,
    config: { headers: { 'Content-Type': 'multipart/form-data' } }
  })
    .then(res => res.data)
    .catch(error => console.log(error));
  
};
