import React, { useState } from 'react';
import {
  useLocation,
  Link
} from 'react-router-dom';
import { Form, Button, Message } from 'semantic-ui-react';
import { resetPassword } from 'requests/auth';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PasswordResetForm = () => {
  let initialData = {
    password: '',
    password_confirmation: ''
  };

  let query = useQuery();

  const [data, setData] = useState(initialData);
  const [resetSuccess, setResetSuccess] = useState(false);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    const { password, password_confirmation } = data;

    const reset_password_token = query.get('reset_password_token');

    resetPassword({
      reset_password_token,
      password,
      password_confirmation 
    })
      .then(function() {
        setResetSuccess(true);
      })
      .catch(function(error) {
        setData({ ...data, error: error });
      });
  };

  return (
    <>
      {
        resetSuccess ? (
          <div>
            <h1>Password reset!</h1>
            <p>You have successfully reset your password.</p>
            <p><Link to={'/login'}>Log in Here</Link> with your new password.</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit} error={data.error ? true : false}>
            <h1>Reset Password</h1>
            <Message
              error
              header="Password reset error"
              content={'Passwords must match.'}
            />      
            <Form.Input
              label="New Password"
              name="password"
              type="password"
              placeholder="New password"
              value={data.password}
              onChange={handleChange}
              error={data.error ? true : false}
              required
            />
            <Form.Input
              label="Confirm new password"
              name="password_confirmation"
              type="password"
              placeholder="Confirm new password"
              value={data.password_confirmation}
              onChange={handleChange}
              error={data.error ? true : false}
              required
            />      
            <Button primary type="submit">
            Submit
            </Button>
          </Form>
  
        )
      }
    </>
  );
};

export default PasswordResetForm;
