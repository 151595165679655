import React, { useContext, useState } from 'react';
import appContext from 'contexts/AppContext';
import NotAdmin from './NotAdmin';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import NewRequest from 'components/Booking/NewRequest';
import NewSeeker from 'screens/dashboard/NewSeeker';
import { HeadingFive } from 'components/common/Textbox';
import BookingsDashboard from 'screens/dashboard/BookingsDashboard';
import RequestsDashboard from 'screens/dashboard/RequestsDashboard';
import LocationPicker  from 'components/Locations/LocationPicker';

const AdminDashboard = () => {
  const { currentUser } = useContext(appContext);
  const [selected, setSelected] = useState('bookings');
  const Panel = 
    <>
      <SubTitleCard>Dashboard <LocationPicker/></SubTitleCard>
      <div className="flex justify-center gap-4 mb-8">
        <NewRequest/>
        <NewSeeker/>
      </div>
      <div className="flex my-6">
        <div 
          className={`text-center cursor-pointer p-3 w-full ${selected == 'requests' ? 'text-primary bg-background-secondary' : 'text-text-gray-80'}`}
          onClick={() => setSelected('requests')}
        >
          <HeadingFive>Service Requests</HeadingFive>
        </div>
        <div 
          className={`text-center cursor-pointer p-3 w-full ${selected == 'bookings' ? 'text-primary bg-background-secondary' : 'text-text-gray-80'}`}
          onClick={() => setSelected('bookings')}
        >
          <HeadingFive>Bookings</HeadingFive>
        </div>
      </div>
      {selected === 'bookings' ?
        <BookingsDashboard/>
        :
        <RequestsDashboard/>
      }
    </>;

  return (
    <Content padding>
      {currentUser && currentUser.type === 'Admin' ?
        Panel : <NotAdmin/> }
    </Content>
  );
};

export default AdminDashboard;

