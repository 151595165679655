import React from 'react';
import Content from 'components/common/Content';
import { Link } from 'react-router-dom';

const NotAuthorized = () => {
  return (
    <Content padding>
      <h1>You are not authorized to do this.</h1>
      <p>Make sure you are <Link to="/login">logged in</Link> and have the right access.</p>
    </Content>
  );
};

export default NotAuthorized;
