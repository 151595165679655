import React from 'react';
import { Link } from 'react-router-dom';
import Table from 'components/common/Table';
import BookingRequestStatus from 'components/common/BookingRequestStatus';
import { getBookings } from 'requests/bookings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';
import Button from 'components/common/Button/ButtonPrimarySquare';
import useFilters from 'hooks/useFilters';

const sortLookup = {
  Client: 'seeker_user_id',
  Submitted: 'created_at',
  Services: 'services',
  Status: 'status'
};

const headers = ['Client', 'Requested', 'Submitted', 'Services', 'Status', ''];

const Requests = ({ bookingParams }) => {
  const { items, filterOptions } = useFilters(null, { item: 'Submitted', direction: 'desc' }, bookingParams, sortLookup, getBookings);

  let rows = items && items.map(booking => booking ? [
    (<Link key={booking.id} to={`/users/${booking.seeker_user_id}`}>
      {booking.first_name_of_seeker}
    </Link>),
    booking.service_requests.map(request => `${request.helper.first_name}, `),
    `${(new Date(booking.created_at)).toDateString()}`,
    booking.services,
    <BookingRequestStatus key={booking.id} booking={booking}/>,
    (
      <>
        <Link key={booking.id} to={`/bookings/${booking.id}`}>
          <Button small >
            View
            <FontAwesomeIcon icon={faArrowAltCircleRight}/>
          </Button>
        </Link>
      </>
    )
  ]: []);

  if (!rows || rows.length < 1) {
    rows = [];
    rows.push([<p key={9999}>-</p>]);
  }

  return (
    <>
      <Table rows={rows} headers={headers} filterOptions={filterOptions} />
    </>
  );
};

export default Requests;

