import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CardDiv = styled.div`
  color: #33C18F;
  border-width: 1px;
  border-radius: .29rem;
  position: relative;
  display: flex;
  gap: 0.4rem;
  align-items: center;
  padding: 0 0.4rem;
  background-color: #E6F2EE;
`;

const Amount = styled.div`
  border-right: 1px solid #33C18F;
  display: flex;
  align-items: center;
  padding-right: 0.4rem;
`;

export default function Card({ text, amount, value, remove }) {
  return (
    <CardDiv className="text-primary bg-background-secondary border-primary">
      {amount > 1 &&
        <Amount className="text-primary bg-background-secondary border-primary">
          <strong>{amount}</strong>
        </Amount>
      }
      {text} 
      <FontAwesomeIcon 
        className="cursor-pointer" 
        icon={faTimes} 
        onClick={(e) => {
          e.stopPropagation();
          remove(value);
        }}
      />
    </CardDiv>
  );
}
