import React, { useState, useEffect, useContext } from 'react';
import { Comment, Header, Button } from 'semantic-ui-react';
import { leaveConversation } from 'requests/chat';
import { getBookingId } from 'requests/bookings';
import appContext from 'contexts/AppContext';
import { useHistory } from 'react-router-dom';

const ChatDetails = ({ conversation }) => {

  const { currentUser } = useContext(appContext);
  const history = useHistory();

  // const [data, setData] = useState({});
  const [bookingId, setBookingId] = useState();

  let otherUser;
  let otherUserId;
  if (conversation.person1_id === currentUser.id) {
    otherUser = conversation.person2;
    otherUserId = conversation.person2_id;
  } else {
    otherUser = conversation.person1;
    otherUserId = conversation.person1_id;
  }

  // useEffect(() => {
  //   getUser(otherUserId)
  //     .then(user => setData(user));
  // }, [otherUserId]);

  useEffect(() => {
    getBookingId(conversation.person1_id, conversation.person2_id)
      .then(result => {
        if (result.data.message !== 'Booking not found') {
          setBookingId(result.data);
        } else {
          setBookingId(null);
        }
      });
  }, [otherUserId, conversation.person1_id, conversation.person2_id]);

  const openProfile = () => {
    otherUser.is_helper?
      history.push(`/helpers/${otherUserId}`) :
      history.push(`/users/${otherUserId}`);
  };

  const viewBooking = () => {
    history.push(`/bookings/${bookingId}`);
  };

  const handleLeaveConversation = async () => {
    leaveConversation(conversation.id)
      .then(() => history.go(0));
  };

  return (
    <Comment.Group minimal>
      <Header className='chat-header' dividing style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        <h4>Details</h4>
      </Header>
      <div className="flex flex-col gap-3">
        <Button basic onClick={openProfile}>
          {otherUser.first_name}&apos;s Profile
        </Button>
        {/* {otherUser.is_helper && <BookingPopup helperInfo={data} seekerInfo={currentUser} />} */}
        {bookingId &&
          <Button basic onClick={viewBooking}>
            View last booking with {otherUser.first_name}
          </Button>
        }
        <Button basic color='red' onClick={handleLeaveConversation}>
              Leave Conversation
        </Button>
      </div>
    </Comment.Group>
  );
};

export default ChatDetails;
