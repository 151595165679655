import React from 'react';
import { PageMargined } from 'components/common/Layout';
import { HeadingTwo, TextBodyReg } from 'components/common/Textbox';    

const CancellationPolicy = () => {
  return (
    <PageMargined>
      <div className="
        sm:py-6
        md:py-24
        "
      >
        <HeadingTwo>Choro Cancellation Policy</HeadingTwo>
        <br />
        <TextBodyReg>
        Cancellations must be made at least <strong>2 HOURS</strong> prior to the visit. 
        Failure to cancel within 2 hours of the visit, will result in a 
        charge for the full amount of the visit.  
        </TextBodyReg>
      </div>
    </PageMargined>
  );
};

export default CancellationPolicy;
