import React from 'react';
import SubTitleCard from '../../components/common/SubTitleCard.jsx';
import Content from '../../components/common/Content.jsx';

const ApplicationSubmitted = () => {
  return (
    <Content padding>
      <SubTitleCard>Thank you</SubTitleCard>
      <h3>Thank you for submitting your Helper Application! </h3>
      <h5>We&apos;ll be contacting you shortly for a quick interview and to get to know more about you.</h5>
    </Content>
  );
};

export default ApplicationSubmitted;
