import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons';

const ButtonRoot = ({ children, onClick, to, className, icon, small, disabled }) => {
  const iconClass = () => {
    return small ? 'text-xl' : 'text-2xl';
  };

  const flexClass = () => {
    const base = 'flex justify-center items-center';
    return small ? `${base} gap-4` : `${base} gap-6`;
  };

  const sizingClass = () => {
    if (small) {
      return (!icon || icon === 'none') ? 'text-sm leading-4 px-4 py-3' : 'text-sm leading-4 px-4 py-2.5';
    } else {
      return (!icon || icon === 'none') ? 'text-xl leading-none px-6 py-3.5' : 'text-xl leading-none px-6 py-3';
    }
  };

  const renderChildren = () => {
    if (icon == 'left') {
      return (
        <>
          <FontAwesomeIcon className={iconClass()} icon={faArrowAltCircleLeft} />
          {children}
        </>
      );
    } else if (icon == 'right') {
      return (
        <>
          {children}
          <FontAwesomeIcon className={iconClass()} icon={faArrowAltCircleRight} />
        </>
      );
    } else {
      return (
        <>{children}</>
      );
    }
  };

  return (
    to ?
      <Link 
        className={`${className} ${sizingClass()}`}
        onClick={onClick}
        to={to}
      >
        <div 
          className={flexClass()}
        >
          {renderChildren()}
        </div>
      </Link>
      :
      <button
        className={`${className} ${sizingClass()} ${disabled ? 'opacity-30' : ''}`}
        onClick={onClick}
        disabled={disabled}
      >
        <div 
          className={flexClass()}
        >
          {renderChildren()}
        </div>
      </button>
  );
};

export default ButtonRoot;
