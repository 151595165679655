import React from 'react';

const PageMargined = ({ children }) => {
  return (
    <div className="
      sm:mx-6
      md:mx-12 
      lg:mx-16 
      xl:mx-24 
      2xl:max-w-7xl 
      2xl:mx-auto"
    >
      {children}
    </div>
  );
};

export default PageMargined;

