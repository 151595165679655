import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import Content from 'components/common/Content.jsx';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { getBooking } from 'requests/bookings';

const PaymentConfirmation = () => {
  const params = useParams();
  const [booking, setBooking] = useState({});
 
  useEffect(() => {
    getBooking(params.booking_id)
      .then(setBooking);
  }, [params.booking_id]);

  return (
    <Content padding>
      <SubTitleCard> <FontAwesomeIcon icon={faWallet}/> Payment method added!</SubTitleCard>
      <h3>
        {booking.first_name_of_seeker} has booked {booking.name_of_helper} to help
        {booking.services && 
          ` with: ${booking.services}`
        }
        &nbsp;on {moment(booking.created_at).format('MMMM Do YYYY, h:mm:ss a')}.
        <br></br>
        <br></br>
        You can monitor the booking <Link to={`/bookings/${booking.id}`}>Here. &nbsp;</Link>
        {booking.first_name_of_seeker} will only be charged once the service has been completed.
      </h3>
    </Content>
  );
};

export default PaymentConfirmation;
