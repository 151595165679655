import React, { useState } from 'react';
import { addExtraCharge } from 'requests/bookings';
import { getServices } from 'requests/services';
import { Form, Button, Card, Confirm, Message } from 'semantic-ui-react';
import Dropdown from 'components/common/Dropdown';

export default function AddCharge({ booking, updateBooking }) {
  const [step, setStep] = useState(false);
  const [extraServices, setExtraServices] = useState();
  const [services, setServices] = useState();
  const [description, setDescription] = useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState();

  const start = () => {
    if (!services) {
      getServices()
        .then(services => {
          setServices(services.map(item => {
            return { key: item.id, text: item.name, value: item.id };
          }));
          setStep('service');
        });
    } else {
      setStep('service');
    }
  };
  const charge = () => {
    setShowConfirm(false);
    addExtraCharge(booking.id, extraServices, description)
      .then(extraCharge => {
        setExtraServices();
        setStep();
        updateBooking({ extra_charges: [...booking.extra_charges, extraCharge] });
      })
      .catch(() => {
        setError('Could not create an extra charge at this time.');
      });
  };

  const cancel = () => {
    setExtraServices();
    setStep();
    setShowConfirm();
    setError();
  };

  const setService = (value) => {
    setExtraServices(value);
  };

  return (
    <>
      { !step ? 
        <Button onClick={start}>Add Extra Charge</Button>
        :
        <Card>
          {step == 'service' &&
            <Card.Content>
              <Card.Header className="text-sm"> What Extra Service did you provide?</Card.Header>
              <div className="flex justify-between mt-3">
                <Form.Field >
                  <label>Select Services</label>
                  <Dropdown
                    name="offered_services"
                    placeholder="Filter by type of help"
                    onChange={setService}
                    options={services}
                  />
                </Form.Field>
              </div>
            </Card.Content>
          }
          { step == 'description' && 
            <Card.Content>
              <Card.Header className="text-sm">Why is this extra charge being applied?</Card.Header>
              <textarea
                className="w-full mt-2 p-2" 
                name="description"
                placeholder=' ...'
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              /> 
            </Card.Content>
          }
          <Card.Content extra className="justify-end flex">
            <Button onClick={cancel} size="mini" basic color="red" content="red">Cancel</Button>
            {step == 'service' && extraServices && 
              <Button onClick={() => setStep('description')} size="mini" basic color="blue" content="blue">Next</Button>
            }
            {extraServices && step === 'description' && 
              <Button onClick={() => setShowConfirm(true)} size="mini" disabled={!description || !services} basic color="green" content="green" className="mr-0">Charge</Button> 
            }
          </Card.Content>
          <Confirm
            open={showConfirm}
            header="Add extra charge?"
            content={`${booking.name_of_client} will be charged and notified.`}
            onCancel={cancel}
            onConfirm={charge}
          />
          {error && 
            <Message negative>
              <Message.Header>{error}</Message.Header>
            </Message>
          }
        </Card>
      }
    </>
  );
}
