import axios from 'axios';
import { handleRequestError } from 'requests/requestHandlers';

export const getHelperProfile = (profileId) => {
  return axios.get(`/helper_profiles/${profileId}`)
    .then(res => res.data)
    .catch(res => handleRequestError(res, 'getHelperProfile'));
};

export const searchHelperProfiles = (params) => {
  return axios.get('/helper_profiles/search', { params })
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'searchHelperProfile'));
};

export const editHelperProfile = (profile) => {
  return axios.put(`/helper_profiles/${profile.id}`, profile)
    .catch(res => handleRequestError(res, 'editHelperProfile'));
};

export const deleteHelperProfile = (profileId) => {
  return axios
    .delete(`/helper_profiles/${profileId}`)
    .catch(res => handleRequestError(res, 'deleteHelperProfile'));
};
