import React, { useContext } from 'react';
import { Item, Image, Label } from 'semantic-ui-react';
import appContext from 'contexts/AppContext';

const Conversations = ({
  conversations,
  handleClick,
  current_convo_id
}) => {
  const { currentUser } = useContext(appContext);
  const list = conversations.map(conversation => {
    let otherUser;
    // let length = conversation.messages.length;
    if (conversation.person1_id === currentUser.id){
      otherUser = conversation.person2;
      otherUser.id = conversation.person2_id;
    } else {
      otherUser = conversation.person1;
      otherUser.id = conversation.person1_id;
    }

    return (
      <Item
        className={`${current_convo_id === conversation.id ? 'bg-light' : ''}`}
        onClick={() => handleClick(conversation.id)}
        key={conversation.id}
      >
        <Image
          src={otherUser.image}
          avatar
          style={{ fontSize: '25px' }}
          className="ml-3 mt-2 mb-2"
          alt={`${otherUser.first_name}`}
        />
        <Item.Content
          style={{
            position: 'relative',
            paddingTop: '7px'
          }}
        >  
          <Item.Header>{`${otherUser.first_name}`}</Item.Header>
          <Item.Meta style={{ margin: '0' }}>
            { conversation.last_message }
          </Item.Meta>
          {
            (conversation.unread_count > 0) && (
              <Label color='teal' floating style={{
                left: 0
              }}>
                !
              </Label>
            )
          }
         
        </Item.Content>
      </Item>
    );
  });

  return (
    <Item.Group className="convo" link divided>
      {list}
    </Item.Group>
  );
};

export default Conversations;
