import React from 'react';
import { Switch } from 'react-router-dom';

import AuthScreens from './authentication';
import ChatScreens from './chat';
import HelpersScreens from './helpers';
import NotFoundScreens from './notFound';
import NotAuthorizedScreens from './notAuthorized';
import PrivacyScreens from './privacy';
import CancellationPolicyScreens from './cancellationPolicy';
import ProfileScreens from './profile';
import SeekersScreens from './seekers';
import PaymentScreens from './payments';
import TermsAndConditionsScreens from './termsAndConditions';
import Dashboard from './dashboard';
import Applications from './applications';
import BookingsScreens from './bookings';
import RequestSentScreens from './requestSent';
import HelperApplicationScreens from './helperApplication';

const Router = () => {
  return (
    <Switch>
      {
        [   
          ...HelpersScreens,
          ...AuthScreens,
          ...ChatScreens,
          ...PrivacyScreens,
          ...CancellationPolicyScreens,
          ...SeekersScreens,
          ...ProfileScreens,
          ...TermsAndConditionsScreens,
          ...RequestSentScreens,
          ...Dashboard,
          ...Applications,
          ...PaymentScreens,
          ...BookingsScreens,
          ...NotAuthorizedScreens,
          ...HelperApplicationScreens,
          ...NotFoundScreens
        ]
      }
    </Switch>
  );
};

export default Router;
