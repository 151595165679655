import React from 'react'; 

const HeadingTwo = ({ children }) => {
  return (
    <h2 className="
      font-semibold 
      text-5xl
      my-0
      "
    >
      {children}
    </h2>
  );
};

export default HeadingTwo;
