import React from 'react';
import PaymentConfirmation from 'components/Payments/PaymentConfirmation';
import PaymentRegsitrationConfirmation from 'components/Payments/PaymentRegistrationConfirmation';
import UserRoute from 'components/Routes/UserRoute';

const routes = [     
  <UserRoute key="register-for-payment-confirm" path="/confirm-payment-registration"><PaymentRegsitrationConfirmation/></UserRoute>,
  <UserRoute key="booking_paid" path="/bookings/:booking_id/paid"><PaymentConfirmation/></UserRoute>
];

export default routes;
