import axios from 'axios';
import { handleRequestError } from 'requests/requestHandlers';

export const getSeekerProfile = (profileId) => {
  return axios.get(`/seeker_profiles/${profileId}`)
    .then(res => res.data)
    .catch(res => handleRequestError(res, 'getSeekerProfile'));
};

export const searchSeekerProfiles = (params) => {
  return axios.get('/seeker_profiles/search', { params })
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'searchSeekerProfiles'));
};

export const getSeekerProfiles = () => {
  return axios.get('/Seeker_profiles/search')
    .then(res => {
      return res.data;
    })
    .catch(res => handleRequestError(res, 'getSeekerProfiles'));
};

export const createSeekerProfile = (profile) => {
  return axios.post('/seeker_profiles', profile)
    .catch(res => handleRequestError(res, 'createSeekerProfile'));
};

export const updateSeekerProfile = (profile) => {
  return axios.put(`/seeker_profiles/${profile.id}`, profile)
    .catch(function(error) {
      console.log(error);
    })
    .catch(res => handleRequestError(res, 'updateSeekerProfile'));
};

export const deleteSeekerProfile = (id) => {
  return axios.delete(`/seeker_profiles/${id}`)
    .catch(res => handleRequestError(res, 'deleteSeekerProfile'));
};
