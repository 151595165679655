import React from 'react';
import BookingsDashboard from './BookingsDashboard';
import BookingSummary from 'screens/bookings/BookingSummary';
import UserRoute from 'components/Routes/UserRoute';

const routes = [
  <UserRoute key="booking" path="/bookings/:booking_id"><BookingSummary/></UserRoute>,
  <UserRoute key="user_bookings" path="/bookings"><BookingsDashboard/></UserRoute>
];

export default routes;
