import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ childRef }) => {
  return (
    <div 
      className="
        bg-background-dark
        sm:p-6 
        md:px-12 
        md:py-6 
        lg:px-16 
        lg:py-8 
        xl:px-24 
        xl:py-12
        "
      ref={childRef}
    >
      <div className="2xl:mx-auto 2xl:max-w-8xl">
        <div className="flex sm:flex-col">
          <div className="flex-auto">
            <div className="my-2 text-white text-xs">
              &copy; 2019 NEIGHBORLI SERVICES INC.
            </div>
            <div className="text-white text-xs">
              <Link to={'/terms-and-conditions'}><span className="text-white">TERMS AND CONDITIONS</span></Link>
              <span> | </span>
              <Link to={'/privacy'}><span className="text-white">PRIVACY POLICY</span></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
