import React from 'react';
import SubTitleCard from '../../components/common/SubTitleCard.jsx';
import Content from '../../components/common/Content.jsx';
import { Link } from 'react-router-dom';

const requestSent = () => {
  return (
    <Content padding>
      <SubTitleCard>Thank you</SubTitleCard>
      <h3>
        The helper will be in contact with you!
        <br></br>
        You can monitor your bookings <Link to="/bookings">Here.</Link>
      </h3>
    </Content>
  );
};

export default requestSent;
