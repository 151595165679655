import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckSquare, faTimes, faWallet,faConciergeBell, faStar } from '@fortawesome/free-solid-svg-icons';

export default function Status({ status, date }) {
  const past = new Date(date) < new Date();
  if(status === 'approved' || status === 'payment-declined') {
    return (<span>payment required <FontAwesomeIcon icon={faWallet} /></span>);
  } else if (status === 'payment-ready' && !past) {
    return (<span>pending service <FontAwesomeIcon icon={faConciergeBell} /></span>);
  } else if (status === 'payment-ready' && past) {
    return (<span>needs review <FontAwesomeIcon icon={faStar} /></span>);
  } else if (status === 'paid') {
    return (<span>complete <FontAwesomeIcon icon={faCheckSquare} /></span>);
  } else if (status === 'incomplete') {
    return (<span>incomplete <FontAwesomeIcon icon={faTimes} /></span>);
  } else if (status === 'declined') {
    return (<span>declined <FontAwesomeIcon icon={faTimes} /></span>);
  } else if (status === 'cancelled') {
    return (<span>cancelled <FontAwesomeIcon icon={faTimes} /></span>);
  } else {
    return (<span>pending <FontAwesomeIcon icon={faClock} /></span>);
  }
}
