import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import Content from 'components/common/Content';
import { forgotPassword } from 'requests/auth';

const ForgotPasswordForm = () => {
  let initialData = {
    email: ''
  };
  const [data, setData] = useState(initialData);
  const [sent, setSent] = useState(false);

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    const { email } = data;
    forgotPassword(email)
      .then(function() {
        setSent(true);
      })
      .catch(function(error) {
        setData({ ...data, error: error });
      });
  };

  return (
    <Content padding>
      {
        sent ? (
          <div>
            <h1>Email sent!</h1>
            <p>You should receive an email shortly, with instructions on how to reset your password. If you do not receive an email, please check your &ldquot;spam&rdquot; folder.</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit} error={data.error ? true : false}>
            <h1>Login</h1>
            <Message
              error
              header="Error"
              content="Invalid email"
            />      
            <Form.Input
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              value={data.email}
              onChange={handleChange}
              error={data.error ? true : false}
              required
            />
            <Button primary type="submit">
                Submit
            </Button>
          </Form>
        )
      }
    </Content>
  );
};

export default ForgotPasswordForm;
