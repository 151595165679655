import React from 'react';
import { Message } from 'semantic-ui-react';

export default function ExtraCharge({ charge }) {
  return (
    <Message info>
      <Message.Header>Additional Charge</Message.Header>
      <div className="flex justify-between">
        <p>
          {charge.reason}<br></br>
          <small><u>{charge.service_names && charge.service_names.map(name => `${name}, `)}</u></small>
          <br></br>
          <small>{charge.description}</small>
        </p>
        <strong>${Math.round(100 * (charge.charge + charge.platform_charge)) / 100} </strong>
      </div>
    </Message>
  );
}
