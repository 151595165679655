import React, { useState, useEffect, useContext } from 'react';
import { adminGetUsers } from 'requests/admin';
import { Dropdown } from 'semantic-ui-react';
import UserSummary from 'components/Users/UserSummary';
import appContext from 'contexts/AppContext';

const UsersSearch = () => {
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);
  const { adminLocation } = useContext(appContext);

  useEffect(() => {
    let params = adminLocation ? { page: 'all', location: adminLocation } : { page: 'all' };
    adminGetUsers(null, params)
      .then(users => {
        setUsers(users.map(user => ({ text: `${user.first_name} ${user.last_name}`, value: user, key: user.id })));
      });
  }, [adminLocation]);

  const handleChange = (e, { value }) => {
    setSelectedUser(value);
  };

  return (
    <div>
      <Dropdown
        button
        className='icon'
        floating
        labeled
        icon='user'
        options={users}
        search
        onChange={handleChange}
        text='Search Users'
      />
      {
        selectedUser &&
            (<div className="mt-2">
              <UserSummary user={selectedUser}/>
            </div>)
      }
    </div>
  );
};

export default UsersSearch;
