import React from 'react'; 

const LinkReg = (props) => {
  return (
    <a 
      className="
        underline
        font-semibold
        hover:underline
        hover:text-warning-blue
        "
      {...props}
    >
      {props.children}
    </a>
  );
};

export default LinkReg;
