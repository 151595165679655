import axios from 'axios';
import { saveHeaders, removeHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const forgotPassword = (email) => {
  return axios.post('/forgot_password', { email })
    .catch(res => handleRequestError(res, 'forgotPassword'));
};

export const resetPassword = ({ reset_password_token, password, password_confirmation }) => {
  removeHeaders();
  return axios.put('/reset_password', {
    reset_password_token,
    password,
    password_confirmation 
  })
    .catch(res => handleRequestError(res, 'resetPassword'));
};

export const signIn = (email, password) => {
  return axios.post('/auth/sign_in', { email, password })
    .then(res => {
      saveHeaders(res.headers);
      // dont like data.data should change
      return res.data.data;
    });
};
