import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { searchHelperProfiles } from 'requests/helperProfiles';
// import locationAutocomplete from 'services/locationAutocomplete';
import {
  Map as LeafletMap,
  TileLayer,
  Marker,
  Circle,
  Popup
} from 'react-leaflet';
import ListGroup from 'components/common/listGroup';
import useFetchLatLng from 'hooks/useFetchLatLng.jsx';

const HelperMap = ({ user }) => {
  //state data setup
  const initialData = {
    helpers: [],
    filtered_helpers: [],
    center: [49.28, -123.12],
    address: null,
    radius: 3
  };
  const [data, setData] = useState(initialData);
  const { lat, lng } = useFetchLatLng(data.address);

  //set default map center to user location
  useEffect(() => {
    user && user.lat &&
      setData(data => {
        return {
          ...data,
          address: user.address,
          center: [user.lat, user.lng]
        };
      });
  }, [user]);

  //sends search request to backend whenever lat/lng/radius/services updates
  useEffect(() => {
    //only send search request if there's an address or selected services
    if (lat) {
      searchHelperProfiles({
        lat,
        lng,
        radius: data.radius
      })
        .then(helperProfiles => {
          // ** CHANGE to selector
          helperProfiles.map((user) => {
            user.services = user.helper_profile.services;
            user.availability = user.helper_profile.availability;
            user.about = user.helper_profile.about;
            user.how_can_i_help = user.helper_profile.how_can_i_help;
            user.hobbies = user.helper_profile.hobbies;
            user.languages = user.helper_profile.languages;
            return user;
          });
          setData(data => {
            return {
              ...data,
              filtered_helpers: helperProfiles,
              center: lat ? [lat, lng] : data.center
            };
          });
        });
    }
  }, [lat, lng, data.radius]);

  //reset list if no address or offered_services
  useEffect(() => {
    if (!lat) {
      setData(data => {
        return {
          ...data,
          filtered_helpers: data.helpers
        };
      });
    }
  }, [lat]);

  //callback function for resetting center - to be called when clicking on user cards and markers
  const reposition = (lat, lng) => {
    setData(data => {
      return {
        ...data,
        center: [lat, lng]
      };
    });
  };

  //callback function for clicking on map marker
  const clickMarker = event => {
    const { lat, lng } = event.latlng;
    reposition(lat, lng);
  };

  //search radius circle
  const searchRadius = (
    <Circle
      key="searchRadius"
      center={
        (lat && [lat, lng]) ||
        (user && [user.lat, user.lng]) || [0,0]
      }
      radius={data.radius * 1000}
      fillOpacity={0.1}
    />
  );

  //making circles for all helpers
  const circles = data.filtered_helpers.map(helper => (
    <Circle
      onClick={clickMarker}
      key={helper.id}
      center={[helper.lat, helper.lng]}
      radius={100}
      fillOpacity={0.6}
      color="purple"
      stroke={false}
    >
      <Popup>
        <Link to={`./helpers/${helper.id}`}>
          <h5 className="card-title">{helper.first_name}</h5>
        </Link>
        <img
          src={helper.image}
          className="card-img img-fluid height:auto thumbnail"
          alt="..."
        />
        {helper.services &&
          helper.services.map(service => (
            <span key={service.id} className="m-1 p-1 badge badge-success">
              {service.name}
            </span>
          ))}
      </Popup>
    </Circle>
  ));

  return (
    <div className="flex flex-col">
      <div className="flex sm:flex-col gap-2">
        <div className="w-1/2 sm:w-1/1">
          <LeafletMap
            center={data.center}
            zoom={14}
            maxZoom={20}
            attributionControl={true}
            zoomControl={true}
            doubleClickZoom={true}
            scrollWheelZoom={true}
            dragging={true}
            animate={true}
            easeLinearity={0.35}
          >
            <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
            {user && user.lat && (
              <Marker
                onClick={clickMarker}
                position={[user.lat, user.lng]}
              >
                <Popup>
                  <h5>
                    <span role="img" aria-label="home">
                      🏠
                    </span>
                    Your address is:
                  </h5>
                  {user.address}
                </Popup>
              </Marker>
            )}
            {lat && lng && (
              <Marker onClick={clickMarker}  position={[lat, lng]}>
                <Popup>
                  <h5>The address you are searching for is:</h5>
                  {data.address}
                </Popup>
              </Marker>
            )}
            {lat && searchRadius}
            {circles}
          </LeafletMap>
        </div>
        <div className="w-1/2 sm:w-1/1">
          <ListGroup
            items={data.filtered_helpers}
            handleClick={reposition}
            type="helpers"
          />
        </div>
      </div>
    </div>
  );
};

export default HelperMap;
