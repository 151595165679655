import React from 'react';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Link = styled(NavLink)`
  position: relative;
  opacity: 60%;
  text-decoration: none;
  color: black;
  &:hover{
    text-decoration: none;
    color: black;
    opacity: 85%;
    text-decoration: none;
  }
  ${(props) => props.white && css`
  color: white;
  opacity: 100%;
`}
`;

const MenuLink = (props) => {
  return (
    <Link className="text-lg" {...props}>
      {props.children}
    </Link>
  );
};

export default MenuLink;
