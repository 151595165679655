import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import NotAdmin from 'screens/dashboard/NotAdmin';
import Content from 'components/common/Content';
import { Confirm, Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { adminDeclineHelperApplication, adminApproveHelperApplication, adminGetHelperApplications } from 'requests/admin';

const PopupStyle = {
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  'margin-right': '-50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'visible'
};

const HelperApplicationSummary = () => {

  const { currentUser } = useContext(appContext);
  const [user, setUser] = useState({ name: '',
    email: '',
    phone_number: '',
    availability:'',
    why_neighborli_helper: '',
    work_experiences: [],
    volunteer_experiences: [],
    education_experiences: [],
    references: [] });
  const [confirmPopup, setConfirmPopup] = useState(false);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    adminGetHelperApplications(params.helper_application_id)
      .then(setUser);
  }, [params.helper_application_id]);

  const handleApprove = async () => {
    adminApproveHelperApplication(params.helper_application_id, params.user_id)
      .then(() => {
        let path = '/applications';
        history.push(path);
      });
  };

  const handleDecline = async () => {
    adminDeclineHelperApplication(params.helper_application_id, params.user_id)
      .then(() => {
        let path = '/applications';
        history.push(path);
      });
  };

  const workExperience = user.work_experiences.map(experience => {
    return (
      <>
        <p><b>Company:</b> {experience.company}</p>
        <p><b>Location:</b> {experience.location}</p>
        <p><b>Length of time:</b> {experience.length_of_time}</p>
      </>
    );
  });

  const volunteerExperience = user.volunteer_experiences.map(experience => {
    return (
      <>
        <p><b>Organization:</b> {experience.organization}</p>
        <p><b>Length of time:</b> {experience.length_of_time}</p>
      </>
    );
  });

  const educationExperience = user.education_experiences.map(experience => {
    return (
      <>
        <p><b>Name of institution:</b> {experience.name_of_institution}</p>
        <p><b>Field of study:</b> {experience.field_of_study}</p>
        <p><b>Date of gradutation:</b> {experience.graduation_date}</p>
      </>
    );
  });

  const references = user.references.map(reference => {
    return (
      <>
        <p><b>Name:</b> {reference.name}</p>
        <p><b>Email:</b> {reference.email}</p>
        <p><b>Phone number:</b> {reference.phone_number}</p>
        <p><b>Relation to reference:</b> {reference.relation}</p>
      </>
    );
  });

  const userDetails =     
    <>
    
      <h1>{user.name}</h1>
      <p><b>Email:</b> {user.email}</p>
      <p><b>Phone number:</b> {user.phone_number}</p>
      <p><b>Address:</b> {user.address}</p>
      <p><b>Availability:</b> {user.availability}</p>
      <p><b>Type:</b> {user.application_type}</p>
      <p><b>Why do they want to be a Choro Helper:</b> {user.why_neighborli_helper}</p>
      <h3>Work Experiences</h3>
      {workExperience}
      <h3>Volunteer Experiences</h3>
      {volunteerExperience}
      <h3>Education Experiences</h3>
      {educationExperience}
      <h3>References</h3>
      {references}
      <br></br>
      {!user.status && (
        <>
          <Button onClick={handleApprove}>
            Approve
          </Button>
          &nbsp;
          <Button onClick={handleDecline}>
            Decline
          </Button>
        </>
      )}
      {user.status === 'approved' && (
        <>
          <Button onClick={() => setConfirmPopup(true)}>Remove Helper Profile</Button>
          <Confirm
            style={PopupStyle}
            open={confirmPopup}
            onCancel={() => setConfirmPopup(false)}
            onConfirm={handleDecline}
          />
        </>
      )}
    </>;


  return (
    <Content padding>
      <Link to='/applications'>
        <Button
          basic
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Back to applications
        </Button>
      </Link>
      <br></br><br></br>
      {currentUser && currentUser.type === 'Admin' ?    
        userDetails 
        : <NotAdmin/>}
    </Content>
  );

};

export default HelperApplicationSummary;

