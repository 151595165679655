import React from 'react';
import styled from 'styled-components';

const Card = styled.h1`
 display: inline-block;
 font-size: 48px;
 font-weight: 700;
 color: black;
 padding: 22px 30px 22px 30px;
`;


const TitleCard = ({ children }) => {
  return (
    <Card>{children}</Card>
  );
};

export default TitleCard;
