import React from 'react';
import { Image, Card } from 'semantic-ui-react';

const ProfileCard = ({ data /*, type */ }) => {
  const renderCardContent = () => {
    if (data.is_helper) {
      return(
        <>
          <p>
            <b>A little about myself</b>
            <br />
            {data.about}
          </p>
          <p>
            <b>How can I help?</b>
            <br />
            {data.how_can_i_help}
          </p>
          <p>
            <b>What do I like to do for fun?</b>
            <br />
            {data.hobbies}
          </p>
          {data.languages ? 
            <p>
              <b>Additional Languages</b>
              <br />
              {data.languages}
            </p>
            :
            <React.Fragment /> 
          }
        </>
      );
    } else {
      return(
        <>
          {data.description}
        </>
      );
    }
  };


  return (
    <Card
      fluid
    >
      <Image src={data.image} alt="Profile Picture" />
      <Card.Content>
        {renderCardContent()}
      </Card.Content>  
    </Card>
  );
};

export default ProfileCard;
