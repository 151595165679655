export const handleRequestError = (err, name, silent = false) => {
  console.log('error', err);
  let res = err.response;
  if (parseInt(res.status) === 401) {
    window.location.replace('/not-authorized');
  } else {
    console.log(`There was a ${res.status} error during the ${name} request:`, res.data);
    if (!silent) throw new Error(res);
  }
};
