import React from 'react';
import { Segment } from 'semantic-ui-react';

const AvailabilitySegment = ({ availability, type }) => {
  const segment =
    type === 'user' ? (
      <></>
    ) : (
      <Segment>
        <h6>Generally available:</h6>
        {availability}
      </Segment>
    );
  return segment;
};

export default AvailabilitySegment;
