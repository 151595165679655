import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Content from 'components/common/Content';
import {  getBooking } from 'requests/bookings';
import BookingActions from 'screens/bookings/BookingActions';
import BookingInfo from 'screens/bookings/BookingInfo';


const BookingSummary = () => {
  const [booking, setBooking] = useState({});
  const [error, setError] = useState();
  const params = useParams();
  const history = useHistory();

  const updateBookingInfo = (bookingInfo) => {
    setBooking({ ...booking, ...bookingInfo });
  };

  useEffect(() => {
    setError(null);
    getBooking(params.booking_id)
      .then(setBooking)
      .catch(error => {
        history.push('/not-authorized');
        setError(error);
      });
  }, [params.booking_id, history]);

  return (
    <Content padding >
      <BookingInfo booking={booking}/>
      <div className="flex flex-col gap-3 flex-wrap items-start mt-3">
        {error && 
          <>
            <h1>{error}</h1>
          </>
        }
        <BookingActions booking={booking} updateBookingInfo={updateBookingInfo} />
      </div>
    </Content>
  );
};

export default BookingSummary;
