import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Content from 'components/common/Content';
import SubTitleCard from 'components/common/SubTitleCard.jsx';
import { Button } from 'semantic-ui-react';
import Table from 'components/common/Table';
import Status from 'components/common/Status';
import ChatButton from 'components/Chat/ChatButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { getBookings } from 'requests/bookings';

const headers = ['Client', 'Helper', 'Date', 'Requested On', 'Services', 'Status', ''];

const BookingDashboard = () => {
  const [bookings, setBookings] = useState([[]]);

  useEffect(() => {
    getBookings()
      .then(setBookings);
  }, []);
  
  const rows = bookings.map(booking => booking ? [
    (<Link key={booking.id} to={`/users/${booking.seeker_user_id}`}>
      {booking.first_name_of_seeker}
    </Link>),
    (<Link key={booking.id} to={`/users/${booking.user_id}`}>
      {booking.first_name_of_helper}
    </Link>),
    `${(new Date(booking.date)).toDateString()} ${moment(booking.date).format('hh:mm a')}`,
    `${(new Date(booking.created_at)).toDateString()}`,
    booking.services,
    <Status key={booking.id} status={booking.status} date={booking.date}/>,
    (
      <>
        <ChatButton person1_id={booking.user_id} person2_id={booking.seeker_user_id} />
        <Link key={booking.id} to={`/bookings/${booking.id}`}>
          <Button primary basic >
            <FontAwesomeIcon icon={faArrowCircleRight}/>
          </Button>
        </Link>
      </>
    )
  ]: []);
  return (
    <>
      <Content padding >
        <SubTitleCard>Bookings</SubTitleCard>
        <Table rows={rows} headers={headers}/>
      </Content>
    </>
  );
};



export default BookingDashboard;
