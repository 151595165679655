import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { initializePaymentMethod } from 'requests/payments';
import { Elements } from '@stripe/react-stripe-js';
import StripeCardSetup from 'components/common/Payments/StripeCardSetup';

function Payments({ user, updatePaymentMethod }) {
  const [stripePromise, setStripePromise] = useState();
  const [clientSecret, setClientSecret] = useState({});
  const [paymentMethods, setPaymentMethods] = useState();

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_KEY));
  }, []);

  const handlePaymentMethodUpdate = (paymentMethod, refreshSecret = false) => {
    updatePaymentMethod(paymentMethod);
    if (refreshSecret) {
      updateClientSecret();
    }
  };
  
  useEffect(() => {
    if (user.id) {
      updateClientSecret();
    }
  }, [ user.id]);

  const updateClientSecret = () => {
    initializePaymentMethod(user.id)
      .then(data => {
        setClientSecret(data.client_secret);
        if (data.payment_methods && Array.isArray(data.payment_methods) && data.payment_methods.length > 0) {
          setPaymentMethods(data.payment_methods);
        }
      });
  };
  
  return (
    <>
      {stripePromise &&
        <Elements stripe={stripePromise}>
          <StripeCardSetup  paymentMethods={paymentMethods} setPaymentMethods={setPaymentMethods} clientSecret={clientSecret} user={user} updatePaymentMethod={handlePaymentMethodUpdate}/>
        </Elements>
      }
    </>
  );
}

export default Payments;

