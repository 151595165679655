import React, { useState, useEffect } from 'react';
import { Segment } from 'semantic-ui-react';
import { ButtonPrimarySquare } from 'components/common/Button';
import { updateUser } from 'requests/users';

const AdminNotesSegment = ({ data }) => {
  const [note, setNote] = useState(data.admin_notes);

  useEffect(() => {
    setNote(data.admin_notes);
  }, [data.admin_notes]);

  const updateNote = () => {
    updateUser({ id: data.id, admin_notes: note })
      .then(() => {
        location.reload();
      });
  };
  return (
    <Segment className="flex flex-col gap-2">
      <h6>Admin Notes:</h6>
      <textarea
        className="text-sm p-1"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />
      {data.admin_notes != note &&
        <ButtonPrimarySquare small onClick={updateNote}>Update</ButtonPrimarySquare>
      }
    </Segment>
  );
};

export default AdminNotesSegment;
