const startOfWeek = (dayInWeek) => {
  let currentDate = new Date(dayInWeek.setHours(0,0,0,0));
  let firstday = datePlusDays(currentDate, 1 - currentDate.getDay());
  return firstday;
};
  
const datePlusDays = (date, days) => {
  let newDate = new Date(date);
  return new Date(new Date(newDate.setDate(date.getDate() + days)).setHours(0,0,0,0));
};

const toTime = (hour, day, beginningOfWeek) => {
  return new Date(beginningOfWeek.getTime() + (hour * 60 + (day * 24) * 60)*60000).getTime();
};
  
export {
  startOfWeek,
  datePlusDays,
  toTime
};
  
