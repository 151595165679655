import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function HelperAppllicationStatus({ status /*, date */ }) {
  if(status === 'approved') {
    return (<span>Approved <FontAwesomeIcon icon={faCheckSquare} /></span>);
  } if(status === 'declined') {
    return (<span>Declined <FontAwesomeIcon icon={faTimes} /></span>);
  } else {
    return (<span>pending <FontAwesomeIcon icon={faClock} /></span>);
  }
}
