import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { createConversation } from 'requests/chat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/common/Button/ButtonPrimarySquare';
import appContext from 'contexts/AppContext';

export default function ChatButton({ booking, small, seeker, helper }) {
  const history = useHistory();
  const { currentUser } = useContext(appContext);

  const person2_id = (currentUser && currentUser.id);
  let person1_id;
  let name;
  if (seeker) {
    person1_id = booking.seeker_user_id;
    name = booking.first_name_of_seeker;
  } else if (helper) {
    person1_id = booking.user_id;
    name = booking.first_name_of_helper;
  }
  const startChat = () => {
    if (currentUser) {
      let params = { conversation: { person1_id, person2_id } };
      createConversation(params)
        .then(() =>  history.push('../../chat', params));
    } else {
      history.push({
        pathname: '/login',
        state: {
          message: 'You must be logged in to chat.',
          from: location.pathname
        }
      });
    }
  };

  return small ?
    <button onClick={startChat}>
      <FontAwesomeIcon icon={faCommentDots}/>
    </button>
    :
    <Button onClick={startChat} small>
        Chat with {name} <FontAwesomeIcon icon={faCommentDots}/>
    </Button>;
}
