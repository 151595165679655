import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import appContext from 'contexts/AppContext';
import ChatButton from 'components/Chat/ChatButton';
import Button from 'components/common/Button/ButtonPrimarySquare';
import PaymentPopup from 'components/common/Payments/PaymentPopup';
import BookingRating from './BookingRating';
import LeaveRecommendationsSegment from 'components/Profile/LeaveRecommendationsSegment';
import AddCharge from 'components/Booking/AddCharge';
import ConfirmService from './ConfirmService';
import { approveBooking, cancelBooking, declineBooking } from 'requests/bookings';
import NewRequest from 'components/Booking/NewRequest';

const BookingActions = ({ booking, updateBookingInfo }) => {
  const { currentUser } = useContext(appContext);
  const history = useHistory();
  const past = new Date(booking.date) < new Date();
  var TWO_HOURS = 2 * 60 * 60 * 1000; /* ms */
  const cancellable = (new Date(booking.date) - new Date()) > TWO_HOURS || !booking.date;

  const handleApprove = () => {
    approveBooking(booking.id)
      .then(() => history.push('/dashboard'));
  };

  const handleDecline = async () => {
    declineBooking(booking.id)
      .then(() => history.push('/dashboard'));
  };

  const handleCancel = async () => {
    cancelBooking(booking.id)
      .then(() => history.push('/dashboard'));
  };

  const requestIs = (type, statusOptions) => {
    if (!booking || !booking.status) return false;
    if(type === 'seeker' && booking.seeker_user_id !== currentUser.id) return false;
    if(type === 'helper' && booking.user_id !== currentUser.id) return false;
    if (statusOptions && !statusOptions.includes(booking.status.toLowerCase())) return false;
    return true;
  };

  return (
    <div className="flex flex-col gap-3 flex-wrap items-start mt-3">
      {requestIs('helper', ['pending']) && 
        <div>
          <Button
            primary basic
            onClick={handleApprove}>
            Approve
          </Button>
          <Button
            margin
            primary basic
            onClick={handleDecline}>
            Decline
          </Button>
        </div>
      }
      { cancellable &&
          <Button
            small
            onClick={handleCancel}>
            Cancel Request
          </Button>
      }
      {requestIs(null, ['approved', 'payment-declined']) &&
          <PaymentPopup booking={booking}/>
      }
      {requestIs('seeker', ['paid', 'payment-declined']) && past &&
          <BookingRating updateBookingInfo={updateBookingInfo} booking={booking}/>
      }
      {requestIs('helper', ['paid']) && past &&
          <AddCharge booking={booking} updateBooking={updateBookingInfo} />
      }
      {requestIs('helper', ['payment-ready', 'paid', 'incomplete', 'payment-declined']) && past &&
          <ConfirmService updateBookingInfo={updateBookingInfo} booking={booking}/>
      }
      {
        <NewRequest small
          booking={booking}
        />
      }
      {requestIs('seeker', ['paid', 'payment-declined']) && past &&
            <LeaveRecommendationsSegment id={booking.user_id} />
      }
      <ChatButton booking={booking} seeker />
      {booking.helper && <ChatButton booking={booking} helper />}
    </div>
  );
};



export default BookingActions;
