import axios from 'axios';
import { saveHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const getCoupons = () => {
  return axios.get('/coupons')
    .then(res => {
      saveHeaders(res.headers);
      return res.data;
    })
    .catch(res => handleRequestError(res, 'getUser'));
};

export const applyCoupon = (code) => {
  return axios.post('/coupons', { coupon: { code } })
    .then(res => {
      saveHeaders(res.headers);
      return res.data;
    });
};
