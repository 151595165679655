import React from 'react';
import Profile from 'components/Profile';
import UserRoute from 'components/Routes/UserRoute';

const routes = [     
  <UserRoute key="helper-profile" path={'/seekers/:id'} goBack ><Profile/></UserRoute>,
  <UserRoute key="helper-profile" path={'/users/:id'} goBack><Profile/></UserRoute>
];

export default routes;
