import React from 'react';
import { HeadingFive } from 'components/common/Textbox';
import { Label } from 'semantic-ui-react';

const ListGroup = ({ items, type, handleClick }) => {
  if (type === 'helpers') {
    items = items.filter(item => item.charges_enabled);
  }
  return (
    <ul className="list-group search border-gray-300 border-2">
      {items.map(item => (
        <li onClick={()=>handleClick(item.lat, item.lng)} key={item.id + item.lat} className="list-group-item d-flex pr-0">
          <div className="flex gap-4 p-4 border-gray-300 border-b">
            <div className="w-1/3">
              <img
                src={item.image}
                className="object-scale-down rounded"
                alt="..."
              />
            </div>
            <div className="w-2/3">
              <div className="flex flex-col gap-1 text-xs">
                <a href={`./users/${item.id}`} target="_blank" rel="noreferrer">
                  <HeadingFive>{item.first_name}</HeadingFive>
                </a>
                {/* <TextBodyLg>{item.about}</TextBodyLg> */}
                <b>Availability:</b>
                {item.availability}
                <b>Type of Help:</b>
                {item.services && item.services.length > 0 &&
                  <div className="flex flex-wrap gap-1">
                    {item.services.map(service => (
                      <Label
                        key={service.id}
                        size="tiny"
                        className="bg-primary text-white m-0"
                      >
                        {service.name}
                      </Label>
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ListGroup;
