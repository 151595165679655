import React from 'react';
import styled from 'styled-components';

const Card = styled.h1`
 display: inline-block;
 font-size: ${({ small }) => small? '20px' : '24px'};
 font-weight: 700;
 color: black;
 padding: 22px 30px 22px 0px;
`;


const TitleCard = ({ children, small }) => {
  return (
    <Card small={small}>{children}</Card>
  );
};

export default TitleCard;
