import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import { faCreditCard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Confirm } from 'semantic-ui-react';

export default function PaymentMethod({ method, selected, setPaymentMethod, removePaymentMethod }) {
  const [confirm, setConfirm] = useState(false);
  const cardIcon = () => {
    switch(method.brand) {
    case 'visa':
      return  <FontAwesomeIcon icon={faCcVisa} />;
    case 'mastercard':
      return  <FontAwesomeIcon icon={faCcMastercard} />;
    case 'amex':
      return  <FontAwesomeIcon icon={faCcAmex} />;
    default:
      return  <FontAwesomeIcon icon={faCreditCard} />;
    }
  };

  const handleRemovePaymentMethod = () => {
    setConfirm(false);
    removePaymentMethod(method);
  };


  return (
    <div className="flex items-center gap-2">
      <div onClick={() => setPaymentMethod(method.id)} className={`flex w-full bg-grey-50 rounded-md cursor-pointer justify-between p-3 border ${ selected  === method.id ? 'border-green-400 bg-background-secondary' : 'border-ui-gray-40 bg-white' }`}>
        <div className="flex items-center gap-2">
          {cardIcon()}
          <small><strong className="text-gray-600">{method.brand || method.type} </strong></small>
        </div>
        {method.last4 &&
          <div className="flex gap-2 items-center">
            <div className="flex items-center"><span className="leading-3 pt-2">**** **** ****&nbsp;</span><small>{method.last4}</small></div>
            {method.exp_month && <small className="text-xs text-gray-400 mr-4">Expires: {method.exp_month}/{method.exp_year}</small>}
          </div>
        }
        <div></div>
      </div>
      <FontAwesomeIcon 
        onClick={(e) => {
          e.stopPropagation();
          setConfirm(true);
        }} 
        className="relative text-sm text-gray-400 hover:text-gray-600" 
        icon={faTimes} 
      />
      <Confirm
        open={confirm}
        content='Are you sure you want to remove this payment method?'
        onCancel={() => setConfirm(false)}
        onConfirm={handleRemovePaymentMethod}
      />
    </div>
  );
}
