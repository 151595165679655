import axios from 'axios';

export const setDefaultHeaders = () => {
  axios.defaults.headers.common['access-token'] = localStorage.getItem(
    'access-token'
  );
  axios.defaults.headers.common['token-type'] = localStorage.getItem(
    'token-type'
  );
  axios.defaults.headers.common['client'] = localStorage.getItem('client');
  axios.defaults.headers.common['expiry'] = localStorage.getItem('expiry');
  axios.defaults.headers.common['uid'] = localStorage.getItem('uid');
  if (localStorage.getItem('impersonated-access-token') && localStorage.getItem('impersonated-client') && localStorage.getItem('impersonated-uid')) {
    axios.defaults.headers.common['impersonated-access-token'] = localStorage.getItem('impersonated-access-token');
    axios.defaults.headers.common['impersonated-client'] = localStorage.getItem('impersonated-client');
    axios.defaults.headers.common['impersonated-uid'] = localStorage.getItem('impersonated-uid');
  } else {
    delete axios.defaults.headers.common['impersonated-access-token'];
    delete axios.defaults.headers.common['impersonated-client'];
    delete axios.defaults.headers.common['impersonated-uid'];
  }
};

export const removeHeaders = () => {
  delete axios.defaults.headers.common['access-token'];
  delete axios.defaults.headers.common['token-type'];
  delete axios.defaults.headers.common['client'];
  delete axios.defaults.headers.common['expiry'];
  delete axios.defaults.headers.common['uid'];
  delete axios.defaults.headers.common['impersonated-access-token'];
  delete axios.defaults.headers.common['impersonated-client'];
  delete axios.defaults.headers.common['impersonated-uid'];
};

export const removeImpersonationHeaders = () => {
  delete axios.defaults.headers.common['impersonated-access-token'];
  delete axios.defaults.headers.common['impersonated-client'];
  delete axios.defaults.headers.common['impersonated-uid'];
};

export function saveHeaders(headers) {
  if (headers['impersonated-access-token'] && headers['impersonated-client'] && headers['impersonated-uid']){
    localStorage.setItem('impersonated-access-token', headers['impersonated-access-token']);
    localStorage.setItem('impersonated-client', headers['impersonated-client']);
    localStorage.setItem('impersonated-uid', headers['impersonated-uid']);
  } else {    
    if (headers['access-token']) {
      localStorage.setItem('access-token', headers['access-token']);
    }
    if (headers['expiry']) {
      localStorage.setItem('expiry', headers['expiry']);
    }
    localStorage.setItem('token-type', headers['token-type']);
    localStorage.setItem('client', headers['client']);
    localStorage.setItem('uid', headers['uid']);
  }
  setDefaultHeaders();
}
