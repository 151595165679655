import React from 'react';
import Bookings from 'screens/dashboard/Bookings';
import SubTitleCard from 'components/common/SubTitleCard.jsx';

export default function BookingsDashboard() {
  return (
    <>
      <SubTitleCard small >Upcoming bookings</SubTitleCard>
      <Bookings open bookingParams={{ time: 'future', type: 'booking' }}/>
      <SubTitleCard small>Past Bookings</SubTitleCard>
      <Bookings open bookingParams={{ time: 'past', type: 'booking' }}/>
    </>
  );
}
