import React from 'react';
import { Segment } from 'semantic-ui-react';

const RecommendationsSegment = ({ data }) => {
  const recommendations = data.reviews.map(r => <p key={r.id}>{r.name}: &ldquo;{r.text}&rdquo;</p>);

  return (
    <Segment>
      <h6>Recommendations:</h6>
      {recommendations}
    </Segment>
  );
};

export default RecommendationsSegment;
