import React from 'react';
import Content from 'components/common/Content';
import PasswordResetForm from 'components/forms/PasswordResetForm';

const ResetPassword = () => {
  return (
    <Content padding>
      <PasswordResetForm/>
    </Content>
  );
};

export default ResetPassword;
