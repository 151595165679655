import axios from 'axios';
import { removeHeaders, setDefaultHeaders } from 'helpers/headerHelpers';
import { handleRequestError } from 'requests/requestHandlers';

export const getLatLng = (address) => {
  const key = process.env.REACT_APP_GOOGLE_MAP_KEY;
  removeHeaders();
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`
  )
    .then(res => {
      setDefaultHeaders();
      if (res.data.status === 'OK'){
        const { lat, lng } = res.data.results[0].geometry.location;
        return { lat, lng };
      } else {
        return { lat: null, lng: null };
      }
    })
    .catch(res => handleRequestError(res, 'getLatLng'));
};

export const getNeighborhood = (address) => {
  const key = process.env.REACT_APP_GOOGLE_MAP_KEY;
  removeHeaders();
  return axios.get(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`
  )
    .then(res => {
      setDefaultHeaders();
      if (res.data.status === 'OK') {
        const components = res.data.results[0].address_components;
        let neighborhood = '';
        let locality = ''; 
        components.forEach(component => {
          if (component.types.includes('neighborhood')) {
            neighborhood = component.short_name;
          } else if (component.types.includes('locality')) {
            locality = component.short_name;
          }
        });
        if (neighborhood !== '') 
          return neighborhood;
        else if (locality !== '') 
          return locality;
        else
          return '';
      } else {
        return '';
      }
    })
    .catch(res => handleRequestError(res, 'getNeighborhood'));
};
