import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight, faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { startOfWeek, datePlusDays, toTime } from 'helpers/calendar';

const scheduleToBlocks = (schedule) => {
  let blocks = {};
  for (let availability of schedule) {
    for(let i = availability[0]; i <= availability[1]; i += 60) {
      blocks[i] = true;
    }
  }
  return blocks;
};

const blocksToSchedule = (blocks) => {
  let times = Object.keys(blocks).map(time => Number(time)).sort((a, b) => a - b);
  let schedule = [[times[0], times[0]]];
  for (let time of times) {
    if((time - schedule[schedule.length - 1][1]) <= 3600000) {
      schedule[schedule.length - 1][1] = time;
    } else {
      schedule.push([time,time]);
    };
  }
  return schedule;
};

function WeeklyDate({ onChange, weeklySchedule, disabled }) {
  const [beginningOfWeek, setBeginningOfWeek] = useState(startOfWeek(new Date()));
  const [blockStart, setBlockStart] = useState();
  const [blockHover, setBlockHover] = useState();
  const [availableBlocks, setAvailableBlocks] = useState({});

  useEffect(() => {
    if (weeklySchedule && weeklySchedule.length > 0 && Object.keys(availableBlocks).length == 0) {
      setAvailableBlocks(scheduleToBlocks(weeklySchedule));
    }
  }, [weeklySchedule]);

  const toggleWeek = (direction = 1) => {
    setBlockStart(null);
    setBeginningOfWeek(datePlusDays(beginningOfWeek, direction * 7));
  };
  const setBlock = (hour, day, paint) => {
    if (disabled) return;
    if (!blockStart || blockStart.day != day) {
      setBlockStart(blockStart ? null : { day, hour, paint });
    } else {
      // block end
      let newAvailableBlocks = { ...availableBlocks };
      let current = toTime(blockStart.hour, blockStart.day, beginningOfWeek);
      let end = toTime(hour,day, beginningOfWeek);
      let increment = Math.sign(end-current) * 3600000;
      while(current != end) {
        if (blockStart.paint) {
          newAvailableBlocks[current] = true;
        } else {
          delete newAvailableBlocks[current];
        }
        current += increment;
      }
      if (blockStart.paint) {
        newAvailableBlocks[current] = true;
      } else {
        delete newAvailableBlocks[current];
      }
      setBlockStart(null);
      setAvailableBlocks(newAvailableBlocks);
      onChange(blocksToSchedule(newAvailableBlocks));
    }
  };

  const days = [0,1,2,3,4,5,6].map(day => (
    <Day 
      key={day} 
      day={day} 
      timeStart={6} 
      timeEnd={21} 
      clickBlock={setBlock} 
      hoverBlock={setBlockHover}
      blockStart={blockStart}
      blockHover={blockHover}
      availableBlocks={availableBlocks}
      beginningOfWeek={beginningOfWeek}
    />
  ));
  let weekdays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
  let month =  beginningOfWeek.toLocaleString('en-US', { month: 'long' });
  return (
    <>
      <h3 className="m-auto text-center">{month}</h3>
      <div className="flex w-full overflow-x-scroll" style={{ zoom: '60%' }}>
        <div className="sticky left-0 bg-white">
          <div className="flex m-5 gap-4 justify-evenly border-1">
            <h3>&nbsp; <br/> &nbsp;</h3>
          </div>
          <hr className="border-white"/>
          <div className="flex flex-col divide-y">
            <p className="p-2">6<small className="leading-4">am</small></p>
            <p className="p-2">7<small className="leading-4">am</small></p>
            <p className="p-2">9<small className="leading-4">am</small></p>
            <p className="p-2">10<small className="leading-4">am</small></p>
            <p className="p-2">11<small className="leading-4">am</small></p>
            <p className="p-2">12<small className="leading-4">pm</small></p>
            <p className="p-2">1<small className="leading-4">pm</small></p>
            <p className="p-2">2<small className="leading-4">pm</small></p>
            <p className="p-2">3<small className="leading-4">pm</small></p>
            <p className="p-2">4<small className="leading-4">pm</small></p>
            <p className="p-2">5<small className="leading-4">pm</small></p>
            <p className="p-2">6<small className="leading-4">pm</small></p>
            <p className="p-2">7<small className="leading-4">pm</small></p>
            <p className="p-2">8<small className="leading-4">pm</small></p>
            <p className="p-2">9<small className="leading-4">pm</small></p>
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex m-5 gap-4 justify-around text-logo relative">
            <FontAwesomeIcon className="absolute left-0 cursor-pointer" icon={faArrowCircleLeft} onClick={() => toggleWeek(-1)}/>
            {weekdays.map((day, i) => {
              return (<h3 key={i} className="w-10 text-center text-logo font-medium">{day}<br/>{datePlusDays(beginningOfWeek, i).getDate()}</h3>);
            })}
            <FontAwesomeIcon className="absolute right-0 cursor-pointer" icon={faArrowCircleRight} onClick={() => toggleWeek(1)}/>
          </div>
          <hr/>
          <div className="flex divide-white divide-x-2 flex-grow">
            {days}
          </div>
        </div>
      </div>
    </>
  );
}

const Day = ({ day, timeStart, timeEnd, clickBlock, hoverBlock, blockStart, blockHover, availableBlocks, beginningOfWeek }) => {
  let hours = [];
  let hovered = (hour) => {
    if (blockStart) {
      let min = Math.min.apply(Math, [blockStart.hour, blockHover ? blockHover.hour : blockStart.hour]);
      let max = Math.max.apply(Math, [blockStart.hour, blockHover ? blockHover.hour : blockStart.hour]);
      let inRange =  day == blockStart.day && (hour <= max && hour >= min);
      if (inRange) {
        return blockStart.paint ? 'bg-background-secondary' : 'bg-light-red';
      }
    }
    let availableBlock = available(hour);
    return availableBlock ? 'bg-background-secondary' : 'bg-ui-gray-5';
  };

  let available = (hour) => {
    if (availableBlocks[toTime(hour, day, beginningOfWeek)]) {
      return true;
    }
    return false;
  };

  for (let i = timeStart; i < timeEnd; i++) {
    hours.push(
      <p 
        key={i} 
        className={`w-fl p-2 ${hovered(i)}`} 
        style={{ cursor: blockStart ? 'cell' : 'pointer' }}
        onMouseEnter={() => hoverBlock({ hour:i, day })} 
        onClick={() => clickBlock(i, day, !available(i))}
      >
        &nbsp;
      </p>
    );
  }
  return (
    <div className="flex flex-col flex-grow divide-y">
      { hours }
    </div>
  );
};

export default WeeklyDate;
