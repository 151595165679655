
const readableDate = (date) => {
  const formattedDate = new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  );

  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedDate}, ${formattedTime}`;
};


const readableTime = (date) => {
  const formattedTime = new Date(date).toLocaleTimeString(
    'en-us',
    {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit'
    }
  );

  return `${formattedTime}`;
};

export {
  readableDate,
  readableTime
};
