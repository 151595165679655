import React, { useContext } from 'react';
import MenuLink from 'components/common/MenuLink';
import appContext from 'contexts/AppContext';

const LogInOutMenuItem = () => {
  const { setCurrentUser, currentUser, updateImpersonation, impersonating } = useContext(appContext);
  const logOut = () => {
    if (impersonating) {
      updateImpersonation(null);
    } else {
      localStorage.clear();
      setCurrentUser('');
    }
  };
  const menuItem = currentUser ? (
    <>
      <MenuLink to="#">
        {`${currentUser.first_name} ${currentUser.last_name}`}
      </MenuLink>
      <MenuLink
        to="/"
        onClick={logOut}
        active="false"
      >
        { impersonating ? 'Stop Impersonating' : 'Logout' }
      </MenuLink>
    </>
  ) : (
    <MenuLink name="Login" to="/login">Log in</MenuLink>
  );
  return menuItem;
};

export default LogInOutMenuItem;
