import React, { useState, useContext } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { saveCurrentUser } from 'helpers/storageHelpers';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Content from 'components/common/Content';
import appContext from 'contexts/AppContext';
import styled from 'styled-components';
import { signIn } from 'requests/auth';
import { getUser } from 'requests/users';

const Title = styled.p`
  margin-top: 0;
  font-size: 40px;
  font-weight: 800;
`;

const LoginForm = () => {
  const { setCurrentUser } = useContext(appContext);
  const location = useLocation();
  let initialData = {
    email: '',
    password: ''
  };
  const [data, setData] = useState(initialData);
  const [notAdmin, setNotAdmin] = useState(false);
  const history = useHistory();

  const handleChange = (e, { name, value }) => {
    setData({ ...data, [name]: value });
  };

  const handleSubmit = () => {
    const { email, password } = data;
    setNotAdmin(false);
    signIn(email, password)
      .then(user => {
        return getUser(user.id);
      })
      .then((user) => {
        if (user.type === 'Admin') {
          saveCurrentUser(user);
          setCurrentUser(user);
          history.push('/dashboard');
        } else {
          setNotAdmin(true);
        }
      })
      .catch(function(error) {
        setData({ ...data, error: error });
      });
  };

  return (
    <Content padding>
      {location.state && 
        <Message
          warning
          header="Must be logged in"
          content={location.state.message}
        />  
      }
      {notAdmin && 
        <Message
          error
          header="Not Authorized"
          content={<div>
            You must be an admin to use this site. To login with your account visit <a href="www.neighhborli.ca">choro.ca</a>.
          </div>}
        /> 
      }
      <Form onSubmit={handleSubmit} error={data.error ? true : false}>
        <Title>Login</Title>
        <Message
          error
          header="Login error"
          content="Invalid email or password"
        />  
        <Form.Input
          label="E-mail"
          name="email"
          type="email"
          placeholder="E-mail"
          value={data.email}
          onChange={handleChange}
          error={data.error ? true : false}
          required
        />
        <Form.Input
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          value={data.password}
          onChange={handleChange}
          error={data.error ? true : false}
          required
        />
        <Button primary type="submit">
          Submit
        </Button>

        <p>Forgot your password? <Link to={'/forgot_password'}>Reset it Here</Link></p>
      </Form>
    </Content>
  );
};

export default LoginForm;
